import React from 'react';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import CmsLinks from '../ui/CmsLinks';
import styled from '@mui/system/styled';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { sanitize } from '@/utils/miscellaneous';
import { WPFullDoubleColumn } from '@/data/wp/operations/queries/fragments/fascia-full-double-column';

interface FullDoubleColumnProps extends WPFullDoubleColumn {}

const StyledTitle = styled(Typography)({
  color: '#ffffff',
  fontSize: '34px',
  fontWeight: '800',
  letterSpacing: '-0.23px',
  lineHeight: '33px',
});

const StyledDesc = styled(Typography)({
  color: '#ffffff',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0',
});

const FullDoubleColumn: React.FC<FullDoubleColumnProps> = ({ backgroundImage, title, desc, desc2, links }) => {
  return (
    <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
      {backgroundImage &&
        <Image
          layout="fill"
          src={backgroundImage.mediaItemUrl}
          alt={backgroundImage.altText}
          objectFit="cover"
        />
      }
      <Container sx={{ position: 'relative', zIndex: '20', backgroundColor: 'transparent', paddingTop: { sm: 13.5, xs: 6.625 }, paddingBottom: { sm: 10.25, xs: 13.25 } }}>
        <Grid container rowSpacing={0} sx={{ position: 'relative', padding: { sm: 0, xs: '0 16px' } }}>
          <Grid item sm={7} xs={12}>
            {title &&
              <StyledTitle mt={0} sx={{ mb: { sm: 5, xs: 4 } }}>{title}</StyledTitle>
            }
          </Grid>
          <Grid item sm={4} xs={0}></Grid>
          <Grid item sm={5} xs={12}>
            {desc &&
              /* @ts-ignore */
              <StyledDesc component="div" sx={{ marginBottom: { sm: '0', xs: 5 } }} dangerouslySetInnerHTML={{__html: sanitize(desc)}} />
            }
          </Grid>
          <Grid item sm={1} xs={0}></Grid>
          <Grid item sm={5} xs={12}>
            {desc2 &&
              /* @ts-ignore */
              <StyledDesc component="div" sx={{ marginBottom: { sm: '0', xs: 4 } }} dangerouslySetInnerHTML={{__html: sanitize(desc2)}} />
            }
          </Grid>
          <Grid item sm={1} xs={0}></Grid>
          <Grid item xs={12}>
            {links && links.length > 0 &&
              <CmsLinks data={links} direction="row" sx={{ marginTop: { sm: 4, xs: 0 } }}></CmsLinks>
            }
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default FullDoubleColumn;

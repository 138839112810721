import React, { useEffect, useMemo, useState } from 'react';
import isEmpty from "lodash/isEmpty";
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation'
import { useDataPagination } from '@/utils/pagination';
import { buildPageUrl, goToPaginationPageUrl, PageType } from '@/utils/router';
import styled from '@mui/system/styled';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CmpPagination from '@/components/ui/Pagination';
import GrayCard from '@/components/ui/GrayCard';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { WPPostList } from '@/data/wp/operations/queries/fragments/fascia-post-list';
import { WPLink, WPLinks_COLORE, WPLinks_SIZE, WPLinks_VARIANTE } from '@/data/wp/operations/queries/fragments/link';
import { WPTranslation } from '@/data/wp/operations/queries/fragments/languages';

const StyledCategories = styled(Stack)({
  marginBottom: "50px",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  flexWrap: 'wrap',
  '& .MuiToggleButtonGroup-grouped': {
    margin: '7px 14px',
    padding: '17px',
    border: 0,
    '&.MuiToggleButton-root span': {
      display: 'inline-block',
      marginLeft: '60px',
      fontWeight: 'normal',
    },
    '&.MuiToggleButton-standard': {
      background: '#f1f1f1',
      color: '#182641',
      fontSize: '16px',
      lineHeight: '20px',
    },
    '&.Mui-selected': {
      background: '#182641',
      color: '#fff',
    },
    '&.MuiToggleButton-standard:hover': {
      background: '#182641',
      color: '#fff',
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      marginLeft: 0,
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

interface PostListProps extends WPPostList {
  _data: {
    _mainCategorySlug: string,
    _subCategories?: any,
    _subCategoriesCounter?: any,
    _posts: any,
  },
  translations?: WPTranslation[],
}

/**
 * Mostra un elenco di post.
 *
 * Esempio per pagina elenco realizzazioni:
 *  <PostList data={ _data: { _mainCategorySlug="projects", _subCategories: {..}, _posts: {..}}, showCategoryFilters={true}></PostList>
 *
 * Esempio per homepage:
 *  <PostList data={ _data: { _mainCategorySlug="projects", _posts: {...}}}></PostList>
 */
const PostList: React.FC<PostListProps> = ({ _data, showCategoryFilters = false, itemsPerRow, preventAccessToNonItUsers = false, translations }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const {locale} = router;
  const [page, setPage] = useState(1);
  const [subCategoryId, setSubCategoryId] = useState<number | null>(null);

  const filteredData = useMemo(() => _data?._posts?.filter((item: any) => {
    const itemData = item?.node;
    if (subCategoryId && itemData._subCategory && subCategoryId !== itemData._subCategory?.node?.id) {
      return false;
    } else {
      return true;
    }
  }), [_data?._posts, subCategoryId]);

  let gridItemColsSm = 0;
  let gridItemColsMd = 0;
  switch (itemsPerRow) {
    case '2':
      gridItemColsSm = 6;
      gridItemColsMd = 6;
      break;
    case '4':
      gridItemColsSm = 6;
      gridItemColsMd = 3;
      break;
    case '3':
    default:
      gridItemColsSm = 6;
      gridItemColsMd = 4;
      break;
  }

  const pageSize = parseInt(process.env.NEXT_PUBLIC_POST_3COL_PAGE_SIZE || '12');
  const pagesCount = Math.ceil((filteredData?.length || 0) / pageSize);
  const enablePagination = pagesCount > 1;
  const paginatedData = useDataPagination(filteredData, pageSize);

  function goToPage(page: number) {
    setPage(page);
    paginatedData.jump(page);
    goToPaginationPageUrl(router, page);
  }

  /* @ts-ignore */
  const handleSubCategory = (e: any, subCategoryId: number | null) => {
    goToPage(1);
    setSubCategoryId(subCategoryId);
  };

  // Resetto filtri e paginazione al cambio lingua
  useEffect(() => {
    if (enablePagination) {
      goToPage(1);
    }
    setSubCategoryId(null);
  // Ometto volutamente le dipendenze enablePagination e goToPage.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  // Controllo che questa istanza di PostList possa essere visto dall'utente corrente in base alla lingua.
  //
  // Faccio redirect, per impedire l'accesso, se si verificano:
  // 1) flag preventAccessToNonItUsers è true fascia WP
  // 2) la lingua della corrente della pagina è l'italiano
  // 3) se il browser dell'utente non ha l'italiano tra le lingue accettate
  //  Nota: per comodità uso la lingua restituita da navigator.language.
  //   Un approccio più robusto sarebbe di usare l'header HTTP Accepted-Languages,
  //   che però è accessibile solo lato server, e richiedere una api custom.
  useEffect(() => {
    const navigatorLanguage = (window.navigator as any)['userLanguage'] || window.navigator.language;
    const navigatorLangCode = navigatorLanguage?.split('-')?.[0].toLowerCase() || process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'it';

    const hasUserItLanguage = navigatorLangCode === 'it';
    if (preventAccessToNonItUsers === true && locale === 'it' && !hasUserItLanguage) {

      // Il redirect viene fatto alla sessa pagina (es: /[lang]/p/cataloghi)
      // caso 1) nella lingua rilevata per l'utente se presente tra le traduzioni della pagina
      let translation = translations?.find((translation) => translation.language.slug === navigatorLangCode);
      let translationUrl = translation?.uri;

      // caso 2) alla stessa pagina in inglese, se presente
      if (!translation || !translation?.uri) {
        translation = translations?.find((translation) => translation.language.slug === 'en');
        translationUrl = translation?.uri;
      }

      // caso 3) alla homepage se non trovo le corrispondenze sopra
      if (!translation || !translation?.uri) {
        translationUrl = buildPageUrl(PageType.HOME, {}, navigatorLangCode);
      }
      if (!translationUrl) {
        translationUrl = buildPageUrl(PageType.HOME, {}, 'it');
      }

      router.push({ pathname: translationUrl }, undefined, { locale: false });
    }
  }, [locale, translations, preventAccessToNonItUsers, router]);

  if (!filteredData) {
    return <></>
  }

  return <Box sx={{ py: "60px" }}>
    <Container fixed sx={{ padding: '0 16px' }}>
      {showCategoryFilters && !isEmpty(_data?._subCategories) &&
        <StyledCategories direction="row" spacing={2} justifyContent="space-between" alignItems="end">
          <StyledToggleButtonGroup
              value={subCategoryId}
              exclusive
              onChange={handleSubCategory}
          >
            {_data?._subCategories?.map((subCat: any, index: number) => {
              const count = _data?._subCategoriesCounter[subCat?.node?.id];
              if (count) {
                return <ToggleButton key={index} value={subCat?.node?.id} size="large" disableRipple={true}>
                  {subCat?.node?.name} <span>{count}</span>
                </ToggleButton>
              }
            })}
          </StyledToggleButtonGroup>
        </StyledCategories>
      }
    </Container>
    <Container fixed sx={{ padding: '0 16px' }}>
      <Grid container>
        {paginatedData.currentData().map((item: any, index: number) => {
          const itemData = item?.node;
          let detailUrl: string | undefined = buildPageUrl(PageType.POST_DETAIL, { category: _data?._mainCategorySlug, slug: itemData?.slug });
          const links: WPLink[] = []

          if (itemData.fieldsArticoli.pdf) {
            detailUrl = undefined;
            links.push({
              __typename: "DefaultTemplate_Fasce_Fasce_GrayCard_Links_LinkEsterno",
              url: itemData.fieldsArticoli.pdf?.mediaItemUrl,
              label: t('download-pdf', {}, {default: 'Download PDF'}),
              target: true,
              download: true,
              variante: WPLinks_VARIANTE.filledButton,
              colore: WPLinks_COLORE.primary,
              dimensione: WPLinks_SIZE.large
            });
          }

          if (itemData.fieldsArticoli.flipbookUrl) {
            detailUrl = undefined;
            links.push({
              __typename: "DefaultTemplate_Fasce_Fasce_GrayCard_Links_LinkEsterno",
              url: itemData.fieldsArticoli.flipbookUrl ,
              label: t('sfoglia-online', {}, {default: 'Sfoglia online'}),
              target: true,
              variante: WPLinks_VARIANTE.outlinedButton,
              colore: WPLinks_COLORE.primary,
              dimensione: WPLinks_SIZE.large
            });
          }

          return (
            <Grid key={index} item sm={gridItemColsSm} md={gridItemColsMd} sx={{display: 'flex', width: '100%'}}>
              <GrayCard
                url={detailUrl}
                img={itemData.featuredImage?.node}
                occhiello={itemData._subCategory?.node?.name}
                title={itemData.title}
                data={itemData.fieldsArticoli?.date}
                text={itemData.excerpt}
                links={links}
              />
            </Grid>
          )
        })}
      </Grid>
    </Container>
    {enablePagination &&
      <Container fixed sx={{ padding: '0 16px', mt: '80px' }}>
        <Stack direction="row">
          {/* @ts-ignore */}
          <CmpPagination page={page} numOfPage={pagesCount} onChange={(e: any, page: number) => goToPage(page)} />
        </Stack>
      </Container>
    }
  </Box>
}

export default PostList;

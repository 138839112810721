import React from 'react';
import Typography from '@mui/material/Typography';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import Image from 'next/image';
import CmsLinks from '../ui/CmsLinks';
import styled from '@mui/system/styled';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '@/mui/theme';
import { WPHero, WPHeroHeight, WPHeroTextLightness } from '@/data/wp/operations/queries/fragments/fascia-hero-banner';

interface HeroProps extends WPHero {}

const StyledSwiper = styled(Swiper)({
  '&.hide-dots .pagination-container': {
    display: 'none'
  },
  '.swiper-pagination-bullet': {
    height: '6.87px',
    width: '50.17px',
    borderRadius: '0',
    marginRight: '8.25px',
    opacity: '0.26',
    backgroundColor: '#ffffff',
    '&:last-of-type': {
      marginRight: '0'
    },
    '&.swiper-pagination-bullet-active': {
      opacity: '1'
    }
  },
  [theme.breakpoints.up('sm')]: {
    '.swiper-slide': {
      display: 'flex',
      alignItems: 'center',
      '> span': {
        position: 'absolute!important',
        width: '100%!important',
        height: '100%!important',
        top: 0,
        left: 0,
        zIndex: '-1',
      }
    },
    '.swiper-pagination': {
      height: '30px',
      width: '100%',
      display: 'flex',
      alignItems: 'flex-end',
      position: 'relative',
    },
    '.swiper-pagination-bullet': {
      height: '10px',
      width: '73px',
      marginRight: '12px',
    }
  },
});

const StyledOcchiello = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    fontSize: '18px',
    lineHeight: '21px',
  },
  fontSize: '12px',
  fontWeight: 'bold',
  letterSpacing: '0',
  lineHeight: '16px',
});

const StyledTitle = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    fontSize: '51px',
    lineHeight: '54px',
    letterSpacing: '0',
  },
  fontSize: '24px',
  fontWeight: '800',
  letterSpacing: '-0.1px',
  lineHeight: '30px',
  '&.small': {
    [theme.breakpoints.up('sm')]: {
      fontSize: '46px',
      lineHeight: '46px',
      letterSpacing: '-0.82px',
    },
  }
});

const StyledDesc = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    fontSize: '22px',
    lineHeight: '28px',
  },
  fontSize: '16px',
  letterSpacing: '0.1px',
  lineHeight: '24px',
  '&.small': {
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      letterSpacing: '0',
    },
    fontSize: '15px',
    letterSpacing: '-0.1px',
    lineHeight: '22px',
  },
  '&.medium': {
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      letterSpacing: '0',
    },
    fontSize: '15px',
    letterSpacing: '-0.1px',
    lineHeight: '22px',
  }
});

// install Swiper modules
SwiperCore.use([Pagination]);

const Hero: React.FC<HeroProps> = ({ height = WPHeroHeight.main, slides }) => {
  const matches = useMediaQuery('(min-width:600px)');
  const showDots = slides.length > 1;

  let desktopHeight = 600;
  switch (height) {
    case WPHeroHeight.medium:
      desktopHeight = 466;
      break;
    case WPHeroHeight.small:
      desktopHeight = 267;
      break;
  }

  return (
    <StyledSwiper
      className={!showDots ? 'hide-dots' : ''}
      spaceBetween={0}
      slidesPerView={1}
      pagination={{
        el: '.swiper-pagination',
        bulletElement: 'div',
        type: 'bullets',
        clickable: true,
      }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
      {slides.map((slideContent, index) => {
        return <SwiperSlide key={index} style={{ height: matches ? desktopHeight : "auto" }}>
          {matches && slideContent.backgroundImage &&
            <Image
              layout="responsive"
              src={slideContent.backgroundImage.mediaItemUrl}
              alt={slideContent.backgroundImage.altText}
              width={slideContent.backgroundImage.mediaDetails.width}
              height={slideContent.backgroundImage.mediaDetails.height}
              objectFit="cover"
            />
          }
          {!matches && slideContent.backgroundImage &&
            <Image
              layout="responsive"
              src={slideContent.backgroundImage.mediaItemUrl}
              alt={slideContent.backgroundImage.altText}
              width={"415px"}
              height={"265px"}
              objectFit="cover"
              objectPosition={height === WPHeroHeight.small ? "left" : "center"}
            />
          }
          <Container sx={{
            position: { sm: 'unset', xs: height === WPHeroHeight.small ? 'absolute' : 'unset' },
            top: { sm: 'unset', xs: height === WPHeroHeight.small ? '0' : 'unset' },
            height: { sm: 'auto', xs: height === WPHeroHeight.small ? '100%' : 'auto' },
            display: { sm: 'block', xs: height === WPHeroHeight.small ? 'flex' : 'block' },
            alignItems: { sm: 'unset', xs: height === WPHeroHeight.small ? 'center' : 'unset' },
            mb: { sm: 0, xs: '30px' }
          }}>
            <Grid container rowSpacing={0}>
              <Grid item sm={7} xs={12}>
                {slideContent.occhiello &&
                  <StyledOcchiello className={height} sx={{ color: {
                    sm: slideContent.textLightness === WPHeroTextLightness.dark ? '#000000': '#ffffff',
                    xs: height === WPHeroHeight.small && slideContent.textLightness === WPHeroTextLightness.light ? '#ffffff' : '#000000'
                  }, marginBottom: { sm: 1.75, xs: 1 }, marginTop: { sm: 0, xs: height === WPHeroHeight.small ? '0' : 5 } }}>{slideContent.occhiello}</StyledOcchiello>
                }
                {slideContent.title &&
                  <StyledTitle className={height} mb={1.75} sx={{ color: {
                    sm: slideContent.textLightness === WPHeroTextLightness.dark ? '#182641': '#ffffff',
                    xs: height === WPHeroHeight.small && slideContent.textLightness === WPHeroTextLightness.light ? '#ffffff' : '#000000'
                  }, marginTop: { sm: '0', xs: slideContent.occhiello || height === WPHeroHeight.small ? '0' : 5 } }}>{slideContent.title}</StyledTitle>
                }
              </Grid>
              <Grid item sm={5} xs={0}></Grid>
              <Grid item sm={5} xs={12}>
                {slideContent.desc &&
                  <StyledDesc className={height} sx={{ color: {
                    sm: slideContent.textLightness === WPHeroTextLightness.dark ? '#000000': '#ffffff',
                    xs: height === WPHeroHeight.small && slideContent.textLightness === WPHeroTextLightness.light ? '#ffffff' : '#000000'
                  }, marginBottom: '0' }}>{slideContent.desc}</StyledDesc>
                }
              </Grid>
              <Grid item sm={7} xs={0}></Grid>
              <Grid item sm={7} xs={12}>
                {slideContent.links && slideContent.links.length > 0 &&
                  <CmsLinks data={slideContent.links} direction="row" sx={{ marginTop: { sm: 4, xs: 2.625 } }}></CmsLinks>
                }
              </Grid>
            </Grid>
          </Container>
        </SwiperSlide>
      })}
      <Container className="pagination-container" sx={{ bottom: { sm: '69px', xs: 'unset' }, top: { sm: 'unset', xs: '221px' }, position: { sm: 'relative', xs: 'absolute' } }}>
        <Grid container rowSpacing={0}>
          <Grid item xs={12}>
            <div className="swiper-pagination"></div>
          </Grid>
        </Grid>
      </Container>
    </StyledSwiper>
  )
}

export default Hero;

import React from 'react';
import Typography from '@mui/material/Typography';
import { WPImage } from '@/data/wp/operations/queries/fragments/image';
import { WPLink } from '@/data/wp/operations/queries/fragments/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import Image from 'next/image';
import CmsLinks from '../ui/CmsLinks';
import styled from '@mui/system/styled';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '@/mui/theme';
import Box from '@mui/material/Box';
import OmniLink from '../ui/OmniLink';
import { sanitize } from '@/utils/miscellaneous';

interface NewsSliderProps {
  sectionTitle?: string;
  slide: {
    backgroundImage: WPImage;
    title?: string;
    desc?: string;
    links?: WPLink[];
    url: string;
  }[];
}

const StyledSwiper = styled(Swiper)({
  '&.swiper-container-horizontal': {
    position: 'unset',
  },
  [theme.breakpoints.up('sm')]: {
    '.swiper-slide': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    }
  },
  '.swiper-slide': {
    width: '80%',
  },
  '.navigation-container-prev': {
    position: 'absolute',
    left: '-22px',
    top: '50%',
    width: '15px',
    zIndex: '99',
    cursor: 'pointer',
  },
  '.swiper-navigation-prev': {
    '&:after': {
      content: `url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23888f9d'%2F%3E%3C%2Fsvg%3E")`,
      position: 'absolute',
      top: '0',
      left: '6px',
      width: '100%',
    }
  },
  '.navigation-container-next': {
    position: 'absolute',
    right: '-41px',
    top: '50%',
    width: '15px',
    zIndex: '99',
    cursor: 'pointer',
  },
  '.swiper-navigation-next': {
    '&:after': {
      content: `url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23888f9d'%2F%3E%3C%2Fsvg%3E")`,
      position: 'absolute',
      top: '0',
      left: '6px',
      width: '100%',
      transform: 'rotate(180deg)',
    }
  }
});

const StyledSectionTitle = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    color: '#000000',
    fontSize: '34px',
    fontWeight: '800',
    letterSpacing: '-0.23px',
    lineHeight: '22px',
    marginBottom: '27px',
  },
  fontSize: '24px',
  letterSpacing: '-0.1px',
  fontWeight: 'bold',
  lineHeight: '30px',
  marginBottom: '16px',
});

const StyledTitle = styled(Typography)({
  color: '#000000',
  fontSize: '24px',
  fontWeight: '800',
  letterSpacing: '-0.1px',
  lineHeight: '30px',
});

const StyledDesc = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    fontSize: '22px',
    lineHeight: '28px',
    letterSpacing: '-0.31px',
  },
  color: '#000000',
  fontSize: '16px',
  letterSpacing: '0.1px',
  lineHeight: '24px',
});

// install Swiper modules
SwiperCore.use([Navigation]);

const NewsSlider: React.FC<NewsSliderProps> = ({ sectionTitle, slide }) => {
  const isTabletOrDesktop = useMediaQuery('(min-width:600px)');

  return (
    <Box>
      <Container sx={{ paddingRight: { sm: '16px', xs: '0' } }}>
        <Grid container rowSpacing={0} sx={{ position: 'relative' }}>
          {sectionTitle &&
            <Grid item xs={12}>
              <StyledSectionTitle>{sectionTitle}</StyledSectionTitle>
            </Grid>
          }
          <Grid item xs={12} sx={{ position: 'relative' }}>
            {isTabletOrDesktop &&
              <StyledSwiper
                navigation={{
                  nextEl: '.swiper-navigation-next',
                  prevEl: '.swiper-navigation-prev',
                }}
                spaceBetween={27}
                slidesPerView={3}
                slidesPerGroup={3}
                loop={true}
                loopFillGroupWithBlank={true}
              >
                {slide.map((slideContent, index) => (
                  <SwiperSlide key={`desktop-${index}`}>
                    <OmniLink href={slideContent.url} underline="none" sx={{width: '100%'}}>
                      {slideContent.backgroundImage &&
                        <Box>
                          <Image
                            layout="responsive"
                            src={slideContent.backgroundImage.mediaItemUrl}
                            alt={slideContent.backgroundImage.altText}
                            width={slideContent.backgroundImage.mediaDetails?.width}
                            height={slideContent.backgroundImage.mediaDetails?.height}
                          />
                        </Box>
                      }
                      <Grid container rowSpacing={0}>
                        <Grid item xs={12}>
                          {slideContent.title &&
                            <StyledTitle mb={1.75} sx={{ marginTop: { sm: 3, xs: 3 } }}>{slideContent.title}</StyledTitle>
                          }
                        </Grid>
                        <Grid item xs={12}>
                          {slideContent.desc &&
                            /* @ts-ignore */
                            <StyledDesc component="div" sx={{ marginBottom: '0' }} dangerouslySetInnerHTML={{__html: sanitize(slideContent.desc)}}></StyledDesc>
                          }
                        </Grid>
                        <Grid item xs={12}>
                          {slideContent.links && slideContent.links.length > 0 &&
                            <CmsLinks data={slideContent.links} direction="row" sx={{ marginTop: { sm: 4, xs: 2.625 } }}></CmsLinks>
                          }
                        </Grid>
                      </Grid>
                    </OmniLink>
                  </SwiperSlide>
                ))}
                <Box className="navigation-container-prev" style={{
                  top: (slide[0].backgroundImage.mediaDetails.height / 2) - 15.5,
                }}>
                  <div className="swiper-navigation-prev"></div>
                </Box>
                <Box className="navigation-container-next" style={{
                  top: (slide[0].backgroundImage.mediaDetails.height / 2) - 15.5,
                }}>
                  <div className="swiper-navigation-next"></div>
                </Box>
              </StyledSwiper>
            }
            {!isTabletOrDesktop &&
              <StyledSwiper
                spaceBetween={15}
                slidesPerView="auto"
                slidesPerGroup={1}
                loop={true}
              >
                {slide.map((slideContent, index) => (
                  <SwiperSlide key={`mobile-${index}`}>
                    <OmniLink href={slideContent.url} underline="none">
                      {slideContent.backgroundImage &&
                        <Box>
                          <Image
                            layout="responsive"
                            src={slideContent.backgroundImage.mediaItemUrl}
                            alt={slideContent.backgroundImage.altText}
                            width={slideContent.backgroundImage.mediaDetails?.width}
                            height={slideContent.backgroundImage.mediaDetails?.height}
                          />
                        </Box>
                      }
                      <Grid container rowSpacing={0}>
                        <Grid item xs={12}>
                          {slideContent.title &&
                            <StyledTitle mb={1.75} sx={{ marginTop: { sm: 3, xs: 1.625 } }}>{slideContent.title}</StyledTitle>
                          }
                        </Grid>
                        <Grid item xs={12}>
                          {slideContent.desc &&
                            /* @ts-ignore */
                            <StyledDesc component="div" sx={{ marginBottom: '0' }} dangerouslySetInnerHTML={{__html: sanitize(slideContent.desc)}}></StyledDesc>
                          }
                        </Grid>
                        <Grid item xs={12}>
                          {slideContent.links && slideContent.links.length > 0 &&
                            <CmsLinks data={slideContent.links} direction="row" sx={{ marginTop: { sm: 4, xs: 2.625 } }}></CmsLinks>
                          }
                        </Grid>
                      </Grid>
                    </OmniLink>
                  </SwiperSlide>
                ))}
              </StyledSwiper>
            }
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default NewsSlider;

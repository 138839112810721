import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { WPSidedContent } from '@/data/wp/operations/queries/fragments/fascia-sided-content';
import RichText from '@/components/ui/RichText';
import SidebarNav from '@/components/ui/SidebarNav';
import styled from '@mui/system/styled';
import theme from '@/mui/theme';

interface SidedContentProps extends WPSidedContent {
  _data: {
    _pageTitle?: string,
    _pageContent?: string,
    _sideMenus?: object,
  },
};

const StyledTitle = styled(Typography)({
  fontSize:  theme.typography.pxToRem(34),
  lineHeight: theme.typography.pxToRem(36),
  fontWeight: "700",
  letterSpacing: theme.typography.pxToRem(-0.23),
});

const SidedContent: React.FC<SidedContentProps> = ({ _data }) => {
  const sidebarNavMenus = _data?._sideMenus && Object.entries(_data?._sideMenus).map(([, menu]) => {
    return menu?.edges
      ?.filter((item: any) => item?.node?.id && item?.node?.path && item?.node?.label)
      ?.map((item: any) => {
        return {
          id: item?.node?.id,
          href: item?.node?.path,
          label: item?.node?.label
        }
      });
  });

  return <Box sx={{ position: 'relative', pt: '79px', pb: { sm: '68px', xs: '72px' } }}>
    <Container>
      <Grid container rowSpacing={0}>
        <Grid item xs={12} md={4} lg={3}>
          {sidebarNavMenus?.map((menu, index) => {
            return <SidebarNav key={index} items={menu} />
          })}
        </Grid>
        <Grid item xs={12} md={1} lg={1}></Grid>
        <Grid item xs={12} md={6} lg={7}>
          {_data?._pageTitle &&
            <StyledTitle>{_data?._pageTitle}</StyledTitle>
          }
          <RichText content={_data?._pageContent} />
        </Grid>
      </Grid>
    </Container>
  </Box>
}

export default SidedContent;

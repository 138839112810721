import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'next/image';
import styled from '@mui/system/styled';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '@/mui/theme';
import Box from '@mui/material/Box';
import { sanitize } from '@/utils/miscellaneous';
import { WPGroupSlider } from '@/data/wp/operations/queries/fragments/fascia-group-slider';

interface GroupSliderProps extends WPGroupSlider {}

const StyledSwiper = styled(Swiper)({
  '.swiper-slide': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    '> div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '> div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
  [theme.breakpoints.up('sm')]: {
    '.swiper-slide': {
      '> div': {
        '> span': {
          width: '100%!important',
        }
      },
      'p': {
        width: '100%!important'
      },
      '&.swiper-slide-active': {
        'span': {
          border: '2px solid #FF5C04!important',
          'img': {
            padding: '8px!important'
          }
        },
        'p': {
          color: '#FF5C04',
        }
      },
      '&:not(.swiper-slide-active):after': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: '#000000',
        opacity: '0.6',
        zIndex: '10'
      }
    }
  },
});

const StyledSectionTitle = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    marginBottom: '67px',
  },
  fontSize: '51px',
  letterSpacing: '0',
  fontWeight: '800',
  lineHeight: '54px',
  marginBottom: '45px',
  color: '#ffffff',
  textAlign: 'center',
});

const StyledTitle = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    zIndex: '20',
    padding: '0',
  },
  padding: '0 40px',
  color: '#ffffff',
  fontSize: '16px',
  letterSpacing: '0',
  lineHeight: '20px',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  textAlign: 'center',
  fontWeight: 'bold',
});

const StyledDesc = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    padding: '0 31vw'
  },
  'a': {
    color: '#ff5c04',
    fontWeight: 'bold',
    'b, strong': {
      color: '#ff5c04',
    },
  },
  color: '#ffffff',
  fontSize: '16px',
  letterSpacing: '0',
  lineHeight: '24px',
  padding: '20px 40px',
  ul: {
    padding: '0',
  },
  img: {
    display: 'block',
    margin: '0 0 15px',
  },
});

const GroupSlider: React.FC<GroupSliderProps> = ({ sectionTitle, slides }) => {
  const matchesMin600 = useMediaQuery('(min-width:600px)');
  const [descToShow, setDescToShow] = useState(slides[0].desc);

  return (
    <Box sx={{ backgroundColor: '#343d4e', paddingTop: 9, paddingBottom: 9 }}>
      <Grid container rowSpacing={0} sx={{ position: 'relative' }}>
        {sectionTitle &&
          <Grid item xs={12}>
            <StyledSectionTitle>{sectionTitle}</StyledSectionTitle>
          </Grid>
        }
        <Grid item xs={12} sx={{ position: 'relative' }}>
          {matchesMin600 &&
            <StyledSwiper
              spaceBetween={13}
              slidesPerView={4}
              slidesPerGroup={1}
              slideToClickedSlide={true}
              onSlideChange={(swiper: any) => {
                setDescToShow(slides[swiper.activeIndex].desc);
              }}
              onClick={(swiper: any) => {
                // remove all active, next and prev classes and add them to clicked el and related siblings
                swiper.slides.forEach(function (el: any) {
                  el.classList.remove('swiper-slide-prev', 'swiper-slide-next', 'swiper-slide-active');
                });
                if (swiper.clickedIndex > 0) {
                  swiper.slides[swiper.clickedIndex].previousSibling.classList.add('swiper-slide-prev');
                }
                swiper.slides[swiper.clickedIndex].classList.add('swiper-slide-active');
                if (swiper.clickedIndex < (swiper.slides.length - 1)) {
                  swiper.slides[swiper.clickedIndex].nextSibling.classList.add('swiper-slide-next');
                }
                setDescToShow(slides[swiper.clickedIndex].desc);
              }}
            >
              {slides.map((slideContent, index) => (
                <SwiperSlide key={index}>
                  {slideContent.backgroundImage &&
                    <Box sx={{ position: 'relative', width: '100%', height: slideContent.backgroundImage.mediaDetails.height }}>                      
                      <Image
                        layout="fill"
                        src={slideContent.backgroundImage.mediaItemUrl}
                        alt={slideContent.backgroundImage.altText}
                        objectFit="cover"
                        objectPosition="center"
                      />
                      {slideContent.title &&
                        <StyledTitle mb={1.75} sx={{ marginTop: { sm: 3, xs: 3 } }}>{slideContent.title}</StyledTitle>
                      }
                    </Box>
                  }
                </SwiperSlide>
              ))}
              <Grid container rowSpacing={0}>
                <Grid item xs={12}>
                  {descToShow &&
                    /* @ts-ignore */
                    <StyledDesc component="div" sx={{ marginBottom: '0', marginTop: { sm: 8, xs: 3 } }} dangerouslySetInnerHTML={{__html: sanitize(descToShow)}} />
                  }
                </Grid>
              </Grid>
            </StyledSwiper>
          }
          {!matchesMin600 &&
            <StyledSwiper
              slidesPerView="auto"
              slidesPerGroup={1}
              centeredSlides={true}
            >
              {slides.map((slideContent, index) => (
                <SwiperSlide key={index}>
                  <Box sx={{ margin: '0 40px' }}>
                    {slideContent.backgroundImage &&
                      <Image
                        layout="intrinsic"
                        src={slideContent.backgroundImage.mediaItemUrl}
                        alt={slideContent.backgroundImage.altText}
                        width={"415px"}
                        height={"265px"}
                        objectFit="cover"
                        objectPosition="center"
                      />
                    }
                  </Box>
                  <Grid container rowSpacing={0}>
                    <Grid item xs={12}>
                      {slideContent.title &&
                        <StyledTitle mb={1.75} sx={{ marginTop: { sm: 3, xs: 2 } }}>{slideContent.title}</StyledTitle>
                      }
                    </Grid>
                    <Grid item xs={12}>
                      {slideContent.desc &&
                        /* @ts-ignore */
                        <StyledDesc component="div" sx={{ marginBottom: '0' }} dangerouslySetInnerHTML={{__html: sanitize(slideContent.desc)}} />
                      }
                    </Grid>
                  </Grid>
                </SwiperSlide>
              ))}
            </StyledSwiper>
          }
        </Grid>
      </Grid>
    </Box>
  )
}

export default GroupSlider;

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import styled from '@mui/system/styled';
import theme from '@/mui/theme';
import { sanitize } from '@/utils/miscellaneous';
import { getIconComponentByName } from '@/utils/icons-map';
import { WPCenteredTextIcon } from '@/data/wp/operations/queries/fragments/fascia-centered-text-icon';

interface CenteredTextIconProps extends WPCenteredTextIcon {}

const StyledTitle = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    marginBottom: '53px'
  },
  color: "#182641",
  fontSize: '51px',
  lineHeight: '54px',
  fontWeight: '800',
  letterSpacing: '0',
  textAlign: 'center',
  marginBottom: '27px',
  '&.smallMarginTitle': {
    marginBottom: '19px'
  }
});

const StyledSubtitle = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    marginBottom: '53px'
  },
  color: "#000000",
  fontSize: '22px',
  fontWeight: 'normal',
  lineHeight: '28px',
  letterSpacing: '-0.31px',
  textAlign: 'center',
  marginBottom: '15px',
});

const StyledDesc = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    marginBottom: '53px'
  },
  color: "#000000",
  fontSize: '16px',
  fontWeight: 'normal',
  lineHeight: '24px',
  letterSpacing: '0.1px',
  textAlign: 'center',
  marginBottom: '0',
  'a': {
    color: '#ff5c04',
    fontWeight: 'bold',
    'b, strong': {
      color: '#ff5c04',
    },
  },
  'b, strong': {
    color: '#1288CF',
    fontWeight: 'bold',
  },
});

const StyledIconDesc = styled(Typography)({
  marginTop: '28px',
  color: "#000000",
  fontSize: '14px',
  fontWeight: 'normal',
  lineHeight: '20px',
  letterSpacing: '0',
  textAlign: 'center',
});

const StyledGrid = styled(Grid)({
  [theme.breakpoints.down('sm')]: {
    margin: '0',
    width: 'auto',
    '> .MuiGrid-item': {
      padding: '0',
      marginBottom: '60px',
      '&:nth-child(odd)': {
        paddingLeft: '11.25px'
      },
      '&:nth-child(even)': {
        paddingRight: '11.25px'
      },
      '&:first-of-type, &:last-of-type': {
        padding: 0,
      }
    }
  },
  'svg': {
    width: '86px',
    height: '86px',
  },
});

const CenteredTextIcon: React.FC<CenteredTextIconProps> = ({ title, subtitle, desc, icons, smallMarginTitle = false }) => {
  const smallMarginTitleClass = smallMarginTitle ? 'smallMarginTitle' : '';

  return <Box sx={{ position: 'relative', pt: { sm: '65px', xs: '60px' }, pb: { sm: '90px', xs: '21px' } }}>
    <Container>
      <Grid container rowSpacing={0}>
        <Grid item xs={0} sm={2} />
        <Grid item xs={12} sm={8}>
          <Box sx={{ position: 'relative', mb: { sm: '0', xs: '54px' } }}>
            {title &&
              <StyledTitle mb={2.5} className={smallMarginTitleClass}>{title}</StyledTitle>
            }
            {subtitle &&
              /* @ts-ignore */
              <StyledSubtitle component="div" mb={2.5} dangerouslySetInnerHTML={{__html: sanitize(subtitle)}}></StyledSubtitle>
            }
            {desc &&
              /* @ts-ignore */
              <StyledDesc component="div" mb={2.5} dangerouslySetInnerHTML={{__html: sanitize(desc)}}></StyledDesc>
            }
          </Box>
        </Grid>
        <Grid item xs={0} sm={2} />
      </Grid>
      <StyledGrid container rowSpacing={0} sx={{ mt: { sm: 5, xs: 2 } }}>
        <Grid item xs={0} sm={1} />
        {icons && icons.map((singleIcon, index) => {
          return <Grid key={index} item xs={6} sm={2}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {singleIcon.icon && getIconComponentByName(singleIcon.icon)}
              {singleIcon.desc !== undefined ? (
                <StyledIconDesc dangerouslySetInnerHTML={{__html: sanitize(singleIcon.desc)}}></StyledIconDesc>
              ) : null}
            </Box>
          </Grid>
        })}
        <Grid item xs={0} sm={1} />
      </StyledGrid>
    </Container>
  </Box>
}

export default CenteredTextIcon;

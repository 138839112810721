import React from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '@/mui/theme';
import { WPSpacer } from '@/data/wp/operations/queries/fragments/fascia-spacer';

interface SpacerProps extends WPSpacer {}

const Spacer: React.FC<SpacerProps> = ({ amount }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (<>
    <Box sx={{ height: `${ isMobile ? Math.round(amount / 2) : amount}px` }}></Box>
  </>)
}

export default Spacer;

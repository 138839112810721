import * as React from 'react';
import { createRef } from 'react';
import styled from '@mui/system/styled';
import theme from '@/mui/theme';
import Box from '@mui/material/Box';
import ScrollSpy from 'react-scrollspy-navigation';
import { WPTopAnchorNav } from '@/data/wp/operations/queries/fragments/fascia-top-anchor-nav';

const headerHeight = 125;
const navbarHeight = 48;

const StyledBox = styled(Box)({
  display: 'none',

  [theme.breakpoints.up('lg')]: {
    display: 'block',
    position: 'fixed',
    width: '100%',
    background: '#F1F1F1',
    zIndex: 1000,
    textAlign: 'center',

    '& a': {
      display: 'inline-block',
      minWidth: '123px',
      padding: '14px',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      textDecoration: 'none',
      color: '#606060',
    },

    '& .active': {
      color: '#FF5C04',
      fontWeight: 'bold',
    }
  }
});

interface TopAnchorNavProps extends WPTopAnchorNav {}

const TopAnchorNav: React.FC<TopAnchorNavProps> = ({ items }) => {
  return <>
    {items && items.length > 0 &&
      <StyledBox component="nav" sx={{top: headerHeight}}>
        <ScrollSpy offsetTop={headerHeight + navbarHeight}>
          {items.map((item, index) => (
            <a key={index} ref={createRef()} href={`#${item.sectionId}`}>{item.label}</a>
          ))}
        </ScrollSpy>
      </StyledBox>
    }
  </>
}

export default TopAnchorNav;

import React from 'react';
import isString from 'lodash/isString';
import { WPImage } from '@/data/wp/operations/queries/fragments/image';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Image from 'next/image';
import Box from '@mui/system/Box';
import OmniLink from './OmniLink';
import { WPLink } from '@/data/wp/operations/queries/fragments/link';
import Paper from '@mui/material/Paper';
import { Link } from '@mui/material';
import CmsLinks from './CmsLinks';
import { sanitize } from '@/utils/miscellaneous';
import ConditionalWrapper from '@/components/ui/ConditionalWrapper';

interface GrayCardProps {
  img?: WPImage,
  occhiello?: string,
  title?: string,
  data?: string,
  text?: string,
  url?: string,
  links?: WPLink[],
  verticalCta?: boolean,
  tag?: string,
  largeCard?: boolean,
}

const GrayCard: React.FC<GrayCardProps> = ({ largeCard, img, occhiello, title, data, text, url, links, verticalCta = false, tag }) => {
  return (
    <Paper elevation={0} square sx={{
      backgroundColor: '#f5f5f5',
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <ConditionalWrapper
        condition={isString(url)}
        wrapper={children => <Link href={url} underline="none" color="inherit" component={OmniLink} sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>{children}</Link>}
      >
        {img &&
          <Box sx={{ flexShrink: 0 }}>
            <Image
              layout="responsive"
              src={img.mediaItemUrl}
              alt={img.altText}
              width={img.mediaDetails.width}
              height={img.mediaDetails.height}
            />
          </Box>
        }
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexBasis: '100%',
          py: 3,
          px: {
            sm: largeCard ? 5.5 : 2.625,
            xs: 2.625,
          }
        }}>
          {occhiello &&
            <Typography sx={{
              color: 'primary.main',
              width: '100%',
              fontSize: '14px',
              lineHeight: '17px',
              fontWeight: 'bold',
              letterSpacing: 'normal',
              marginBottom: '7px',
              textTransform: 'uppercase',
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}>{occhiello}</Typography>
          }
          {title &&
            <Typography sx={{
              width: '100%',
              fontSize: '18px',
              lineHeight: '25px',
              fontWeight: 'bold',
              letterSpacing: '0px',
              marginBottom: '4px',
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}>{title}</Typography>
          }
          {data &&
            <Typography sx={{
              width: '100%',
              fontSize: '14px',
              lineHeight: '20px',
              letterSpacing: '0px',
              marginBottom: text ? '16px' : '0',
            }}>{data}</Typography>
          }
          {text &&
            <Typography
              component="div"
              sx={{
                width: '100%',
                color: '#1C1C1C',
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '0px',
                display: '-webkit-box',
                WebkitLineClamp: 4,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                p: {
                  overflow: 'hidden'
                }
              }}
              dangerouslySetInnerHTML={{ __html: sanitize(text) }} />
          }
          {(tag || links) &&
            <Box sx={{marginTop: 'auto'}}>
              {tag &&
                <Chip label={tag} variant="outlined" sx={{
                  mt: 1.75,
                  borderRadius: '5px',
                  color: '#606060',
                  borderColor: '#606060',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  lineHeight: '20px',
                  letterSpacing: '0',
                  textTransform: 'uppercase',
                }} />
              }
              {links &&
                <CmsLinks enableWrap={true} spacing={1.75} data={links} direction={verticalCta ? 'column' : 'row'} mt={verticalCta ? 3.875 : 2.875} sx={{
                  'a': {
                    padding: '16px 10px',
                  }
                }} />
              }
            </Box>
          }
        </Box>
      </ConditionalWrapper>
    </Paper>
  )
}

export default GrayCard;

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import styled from '@mui/system/styled';
import theme from '@/mui/theme';
import { getIconComponentByName } from '@/utils/icons-map';
import { WPMission } from '@/data/wp/operations/queries/fragments/fascia-mission';

interface MissionProps extends WPMission {}

const StyledSectionTitle = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    marginBottom: "50px"
  },
  color: "#ffffff",
  fontSize: "34px",
  fontWeight: "bold",
  letterSpacing: "-0.23px",
  lineHeight: "35px",
  marginBottom: "38px",
  textAlign: 'center'
});

const StyledTitle = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    textAlign: 'center',
    marginTop: '19px',
  },
  color: "#ffffff",
  fontSize: "18px",
  fontWeight: "bold",
  letterSpacing: "0",
  lineHeight: "25px",
  marginTop: '0',
  marginBottom: "5px",
  textAlign: 'left'
});

const StyledDesc = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    textAlign: 'center',
    padding: '0 25px'
  },
  color: "#ffffff",
  opacity: '92%',
  fontSize: "14px",
  letterSpacing: "0",
  lineHeight: "20px",
  marginBottom: "0",
  textAlign: 'left',
});


const Mission: React.FC<MissionProps> = ({ titleSection, columns }) => {
  return <Box sx={{ position: 'relative', pt: '79px', pb: { sm: '68px', xs: '72px' }, backgroundColor: '#343d4e' }}>
    <Container>
      <Grid container rowSpacing={0}>
        <Grid item xs={0} sm={2} />
        <Grid item xs={12} sm={8}>
          <StyledSectionTitle>{titleSection}</StyledSectionTitle>
        </Grid>
        <Grid item xs={0} sm={2} />
      </Grid>
      <Grid container rowSpacing={0}>
        {columns && columns.map((singleCol, index) => {
          return <Grid key={index} item xs={12} sm={4} sx={{ marginBottom: { sm: 0, xs: 10 }, display: "flex" }}>
            <Grid container rowSpacing={0} sx={{ alignItems: { sm: "flex-start", xs: "center" } }}>
              <Grid item xs={4} sm={12} sx={{ height: "86px", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {singleCol.icon && getIconComponentByName(singleCol.icon)}
              </Grid>
              <Grid item xs={8} sm={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {singleCol.title &&
                  <StyledTitle>{singleCol.title}</StyledTitle>
                }
                {singleCol.desc &&
                  <StyledDesc>{singleCol.desc}</StyledDesc>
                }
              </Grid>
            </Grid>
          </Grid>
        })}
      </Grid>
    </Container>
  </Box>
}

export default Mission;

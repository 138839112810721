import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import styled from '@mui/system/styled';
import theme from '@/mui/theme';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { getIconComponentByName } from '@/utils/icons-map';
import { sanitize } from '@/utils/miscellaneous';
import { WPNormative } from '@/data/wp/operations/queries/fragments/fascia-normative';

interface NormativeProps extends WPNormative {}

const StyledTitle = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    marginBottom: '42px',
  },
  color: "#ffffff",
  fontSize: '34px',
  lineHeight: '42px',
  fontWeight: '800',
  letterSpacing: '-0.23px',
  textAlign: 'center',
  marginBottom: '20px',
});

const StyledSubtitle = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    marginBottom: '55px'
  },
  color: "#FF5C04",
  fontSize: '22px',
  fontWeight: '600',
  lineHeight: '28px',
  letterSpacing: '-0.45px',
  textAlign: 'center',
  marginBottom: '20px',
});

const StyledIconTitle = styled(Typography)({
  fontSize: '18px',
  fontWeight: 'bold',
  lineHeight: '25px',
  letterSpacing: '0',
  marginBottom: '5px',
});

const StyledIconDesc = styled(Typography)({
  fontSize: '14px',
  fontWeight: 'normal',
  lineHeight: '20px',
  letterSpacing: '0',
});

const StyledGrid = styled(Grid)({
  [theme.breakpoints.up('sm')]: {
    marginBottom: '10px',
    flexDirection: 'row',
  },
  marginBottom: '50px',
  flexDirection: 'column',
});

const StyledAccordion = styled(Accordion)({
  backgroundColor: 'transparent',
  color: '#ffffff',
  borderTop: '1px solid #f5f5f5',
  boxShadow: 'unset',
  '&:last-child&:not(.Mui-expanded)': {
    borderBottom: '1px solid #f5f5f5',
  },
  '&.Mui-expanded': {
    marginBottom: '20px',
  }
});

const StyledAccordionSummary = styled(AccordionSummary)({
  padding: '0 10px 0 7px',
  margin: '3px 0',
  'svg': {
    color: '#ffffff',
    transform: 'rotate(-90deg)'
  }
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '0 27px',
});

const StyledContainer = styled(Container)({
  [theme.breakpoints.down('sm')]: {
    padding: '0 32px',
  },
});

const Normative: React.FC<NormativeProps> = ({ title, subtitle, desc, icons, normative }) => {
  const [expanded, setExpanded] = useState(-1);

  const handleChange = (panel: any) => (newExpanded: any) => {
    setExpanded(newExpanded ? panel : false);
  };

  return <Box sx={{ backgroundColor: '#343D4E', position: 'relative', pt: { sm: '65px', xs: '60px' }, pb: { sm: '90px', xs: '21px' } }}>
    <StyledContainer>
      <Grid container rowSpacing={0}>
        <Grid item xs={0} sm={2} />
        <Grid item xs={12} sm={8}>
          <Box sx={{ position: 'relative', mb: { sm: '0', xs: '35px' } }}>
            {title &&
              <StyledTitle mb={2.5}>{title}</StyledTitle>
            }
            {desc &&
              <Typography component="div" sx={{ fontSize: '16px', lineHeight: '24px', color: '#CCCFD4', marginBottom: '40px' }} dangerouslySetInnerHTML={{__html: sanitize(desc)}} />
            }
          </Box>
        </Grid>
        <Grid item xs={0} sm={2} />
      </Grid>
      <StyledGrid container rowSpacing={0}>
        {icons && icons.map((singleIcon, index) => {
          return <Grid key={index} item sm={4} sx={{ marginBottom: { sm: 0, xs: '22px' } }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%', backgroundColor: '#CCCFD4', padding: '16px 30px', borderRadius: '22px', svg: { flexShrink: '0' } }}>
              {singleIcon.icon && getIconComponentByName(singleIcon.icon)}
              <Box sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '18px' }}>
                <StyledIconTitle>
                  {singleIcon.title}
                </StyledIconTitle>
                <StyledIconDesc>
                  {singleIcon.desc}
                </StyledIconDesc>
              </Box>
            </Box>
          </Grid>
        })}
      </StyledGrid>
      <Grid container rowSpacing={0}>
        <Grid item xs={0} sm={2} />
        <Grid item xs={12} sm={8}>
          <Box sx={{ position: 'relative', mt: { xs: '80px', sm: '60px' }, mb: { sm: '0', xs: '20px' } }}>
            {subtitle &&
              <StyledSubtitle mb={2.5}>{subtitle}</StyledSubtitle>
            }
          </Box>
        </Grid>
        <Grid item xs={0} sm={2} />
      </Grid>
      <Grid container rowSpacing={0}>
        <Grid item xs={0} sm={2} />
        <Grid item xs={12} sm={8}>
          <Box sx={{ position: 'relative', mb: { sm: '0', xs: '54px' } }}>
            {normative && normative.map((normativa, index) => {
              return <StyledAccordion
                  key={index}
                  square={true}
                  disableGutters={true}
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                >
                <StyledAccordionSummary
                  expandIcon={<svg width="7px" height="12px" viewBox="0 0 7 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title>69BCE269-4405-4DBF-B40F-FB015F0CD652@1x</title><g id="SITO" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="Contatti" transform="translate(-391.000000, -490.000000)" fill="currentColor"><g id="sidebar-menu" transform="translate(166.000000, 464.000000)"><g id="4.-Icons-/-0.-Icon-Area" transform="translate(225.204505, 26.234835)"><polygon id="Line" transform="translate(3.295495, 5.530330) rotate(-90.000000) translate(-3.295495, -5.530330) " points="3.29549513 6.70450487 7.76516504 2.23483496 8.82582521 3.29549513 3.29549513 8.82582521 -2.23483496 3.29549513 -1.17417479 2.23483496"></polygon></g></g></g></g></svg>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{normativa.normativeName}</Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  {normativa.normativeDesc &&
                    <Typography component="div" sx={{ marginBottom: '17px'  }} dangerouslySetInnerHTML={{__html: sanitize(normativa.normativeDesc)}} />
                  }
                  {normativa.normativePoints && normativa.normativePoints.map((normativaPoint, index) => {
                    return <Box key={index} sx={{ display: 'flex',  flexDirection: { sm: 'row', xs: 'column' }, marginBottom: { sm: 0, xs: '15px' } }}>
                      <Typography sx={{ whiteSpace: 'nowrap', marginRight: '15px' }}>{normativaPoint.normativePointsName}</Typography>
                      <Typography>{normativaPoint.normativePointsValue}</Typography>
                    </Box>
                  })}
                </StyledAccordionDetails>
              </StyledAccordion>
            })}
          </Box>
        </Grid>
        <Grid item xs={0} sm={2} />
      </Grid>
    </StyledContainer>
  </Box>
}

export default Normative;

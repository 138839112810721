import React, { useEffect, useMemo, useState } from 'react';
import isEmpty from "lodash/isEmpty";
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useDataPagination } from '@/utils/pagination';
import { buildPageUrl, goToPaginationPageUrl, PageType } from '@/utils/router';
import styled from '@mui/system/styled';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CmpPagination from '@/components/ui/Pagination';
import GrayCard from '@/components/ui/GrayCard';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { WPNewsEventiFiereList } from '@/data/wp/operations/queries/fragments/fascia-news-list';
import NewsSlider from '@/components/fasce/NewsSlider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const StyledCategories = styled(Stack)({
  marginBottom: "50px",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  flexWrap: 'wrap',
  '& .MuiToggleButtonGroup-grouped': {
    margin: '7px 14px',
    padding: '17px',
    border: 0,
    '&.MuiToggleButton-root span': {
      display: 'inline-block',
      marginLeft: '60px',
      fontWeight: 'normal',
    },
    '&.MuiToggleButton-standard': {
      background: '#f1f1f1',
      color: '#182641',
      fontSize: '16px',
      lineHeight: '20px',
    },
    '&.Mui-selected': {
      background: '#182641',
      color: '#fff',
    },
    '&.MuiToggleButton-standard:hover': {
      background: '#182641',
      color: '#fff',
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      marginLeft: 0,
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

interface NewsEventiFiereListProps extends WPNewsEventiFiereList {
  _data: {
    _mainCategorySlug: string,
    _subCategories?: any,
    _subCategoriesCounter?: any,
    _posts: any,
  },
}

/**
 *
 * Esempio per pagina elenco realizzazioni:
 *  <NewsEventiFiereList data={mainCategorySlug="projects", subCategories: {..} posts: {..}} showCategoryFilters={true}></NewsEventiFiereList>
 *
 * Esempio per homepage:
 *  <NewsEventiFiereList data={mainCategorySlug="projects", posts: {...}} title="Alcune realizzazioni" showFeaturedOnly={true} sliderMode={true}></NewsEventiFiereList>
 *
 */
const NewsEventiFiereList: React.FC<NewsEventiFiereListProps> = ({
  _data, showCategoryFilters = false, showFeaturedOnly = false, sliderMode = false, title
}) => {
  const featuredOnlyMaxItems = 12;
  const { t } = useTranslation('common');
  const router = useRouter();
  const {locale} = router;
  const [orderBy, setOrderBy] = useState('date');
  const [page, setPage] = useState(1);
  const [subCategoryId, setSubCategoryId] = useState<number | null>(null);

  // Suppress ORDER BY selector (not needed)
  const showOrderBy = false;

  const filteredData = useMemo(() => _data?._posts?.filter((item: any) => {
    const itemData = item?.node;
    if (subCategoryId && itemData._subCategory && subCategoryId !== itemData._subCategory?.node?.id) {
      return false;
    } else {
      return true;
    }
  }), [_data?._posts, subCategoryId]);

  const pageSize = parseInt(process.env.NEXT_PUBLIC_POST_3COL_PAGE_SIZE || '12');
  const pagesCount = Math.ceil((filteredData?.length || 0) / pageSize);
  const enablePagination = !showFeaturedOnly && pagesCount > 1;
  const paginatedData = useDataPagination(filteredData, showFeaturedOnly ? featuredOnlyMaxItems: pageSize);

  function goToPage(page: number) {
    setPage(page);
    paginatedData.jump(page);
    goToPaginationPageUrl(router, page);
  }

  /* @ts-ignore */
  const handleSubCategory = (e: any, subCategoryId: number | null) => {
    goToPage(1);
    setSubCategoryId(subCategoryId);
  };

  // Resetto filtri e paginazione al cambio lingua
  useEffect(() => {
    if (enablePagination) {
      goToPage(1);
    }
    setSubCategoryId(null);
  // Ometto volutamente le dipendenze enablePagination e goToPage.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const handleOrderByChange = (event: SelectChangeEvent) => {
    setOrderBy(event.target.value as string);
  };

  if (!filteredData) {
    return <></>
  }

  return <Box sx={{ py: "60px" }}>
    <Container fixed sx={{ padding: '0 16px' }}>
      {(showCategoryFilters || showOrderBy) &&
        <StyledCategories direction="row" spacing={2} justifyContent="space-between" alignItems="end">
          {showCategoryFilters && !isEmpty(_data?._subCategories) &&
            <StyledToggleButtonGroup
                value={subCategoryId}
                exclusive
                onChange={handleSubCategory}
            >
              {_data?._subCategories?.map((subCat: any, index: number) => {
                const count = _data?._subCategoriesCounter[subCat?.node?.id];
                if (count) {
                  return <ToggleButton key={index} value={subCat?.node?.id} size="large" disableRipple={true}>
                    {subCat?.node?.name} <span>{count}</span>
                  </ToggleButton>
                }
              })}
            </StyledToggleButtonGroup>
          }
          {showOrderBy &&
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="nef-orderby-label">{t('order-by', {}, {default: 'Ordina per'})}</InputLabel>
                <Select
                  labelId="nef-orderby-label"
                  id="nef-orderby"
                  value={orderBy}
                  label={t('order-by', {}, {default: 'Ordina per'})}
                  onChange={handleOrderByChange}
                >
                  <MenuItem value="date">{t('order-by-date', {}, {default: 'Data'})}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          }
        </StyledCategories>
      }
    </Container>
    {sliderMode &&
      <NewsSlider
        sectionTitle={title}
        slide={paginatedData.currentData().map((item: any) => {
          const itemData = item?.node;
          const detailUrl = buildPageUrl(PageType.POST_DETAIL, { category: _data?._mainCategorySlug, slug: itemData?.slug });
          return {
            url: detailUrl,
            backgroundImage: itemData.featuredImage?.node,
            title: itemData.title,
            desc: itemData.excerpt,
          }
        })}>
      </NewsSlider>
    }
    {!sliderMode &&
      <Container fixed sx={{ padding: '0 16px' }}>
        <Grid container>
          {paginatedData.currentData().map((item: any, index: number) => {
            const itemData = item?.node;
            const detailUrl = buildPageUrl(PageType.POST_DETAIL, { category: _data?._mainCategorySlug, slug: itemData?.slug });
            return (
              <Grid key={index} item sm={6} md={4} sx={{display: 'flex', width: '100%'}}>
                <GrayCard
                  url={detailUrl}
                  img={itemData.featuredImage?.node}
                  occhiello={itemData._subCategory?.node?.name}
                  title={itemData.title}
                  data={itemData.fieldsArticoli?.date}
                  text={itemData.excerpt}
                />
              </Grid>
            )
          })}
        </Grid>
      </Container>
    }
    {enablePagination &&
      <Container fixed sx={{ padding: '0 16px', mt: '80px' }}>
        <Stack direction="row">
          {/* @ts-ignore */}
          <CmpPagination page={page} numOfPage={pagesCount} onChange={(e: any, page: number) => goToPage(page)} />
        </Stack>
      </Container>
    }
  </Box>
}

export default NewsEventiFiereList;

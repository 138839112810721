import React from "react";

interface ConditionalWrapperProps {
  condition: boolean
  wrapper: (children: React.ReactNode) => React.ReactNode
  children: React.ReactNode
}

/*
 * Conditionally wrap an element with a component.
 *
 * Usage:
 *    <ConditionalWrapper
 *      condition={hasLink}
 *      wrapper={children => <a href={link}>{children}</a>}
 *    >
 *      ...
 *    </ConditionalWrapper>
 */
const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) => <>{condition ? wrapper(children) : children}</>

export default ConditionalWrapper;

import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { WPBrandCategories } from '@/data/wp/operations/queries/fragments/fascia-brand-categories';
import { MainMenuItemData } from '../ui/main-menu';
import { buildHeaderMenuIcon } from '@/utils/icons-map';
import { ThirdLevelMenuItem } from '../ui/main-menu/desktop/ThirdLevelMenuItem';

const StyledBox = styled(Box)({
  columnGap: '20px',
  paddingBottom: '20px',
  columns: '190px 3'
});

interface BrandCategoriesProps extends WPBrandCategories {
  _data: {
    _structuralData: any,
  },
}

/**
 * Mostra l'elenco delle categorie (ciascuna con icona, label e link) per il brand indicato da brandSlug (valori ammessi 'disano', 'fosnova').
 * Le informazioni vengono prese in automatico dal menu wordpress,
 *
 * ASSUMENDO CHE LA PRIMA VOCE SIA "PRODOTTI" E CHE QUESTA CONTENGA LE DUE SOTTOVOCI "DISANO" E "FOSNOVA" (IN QUEST'ORDINE).
 */
const BrandCategories: React.FC<BrandCategoriesProps> = ({ _data, brandSlug }) => {
  // MEGA MENU
  const mainMenus = _data._structuralData?.mainMenus?.edges;
  let SLItemUI: MainMenuItemData | null = null;

  const productItemIndex = 0;
  const brandItemIndex = brandSlug === 'disano' ? 0 : 1;

  if (mainMenus?.length > productItemIndex) {
    // prendo la prima voce che assumo essere "Prodotti"
    const FLItemCMS = mainMenus[productItemIndex];
    if (FLItemCMS?.node?.childItems?.edges && FLItemCMS?.node?.childItems?.edges?.length > brandItemIndex) {
      const SLItemCMS = FLItemCMS?.node?.childItems?.edges[brandItemIndex];
      if (SLItemCMS?.node) {

        // prepare the second level item
        SLItemUI = {
          label: SLItemCMS.node.label,
          url: SLItemCMS.node.url,
        };
        if (SLItemCMS.node?.fieldsMenu?.viewalllabel) {
          SLItemUI.viewAllLabel = SLItemCMS.node?.fieldsMenu?.viewalllabel;
        }

        // look if there is any second level items
        if (SLItemCMS.node?.childItems && SLItemCMS.node.childItems?.edges) {
          const SLItemChildsUI: MainMenuItemData[] = [];
          SLItemCMS.node.childItems.edges.map((TLItemCMS: any) => {
            if (TLItemCMS?.node) {

              // prepare the third level item
              const TLItemUI: MainMenuItemData = {
                label: TLItemCMS.node.label,
                url: TLItemCMS.node.url,
              };

              if (TLItemCMS.node?.fieldsMenu?.icon) {
                TLItemUI.icon = buildHeaderMenuIcon('menu-' + TLItemCMS.node.fieldsMenu.icon);
              }

              SLItemChildsUI.push(TLItemUI);
            }
          });
          if (SLItemChildsUI.length > 0) {
            SLItemUI.items = SLItemChildsUI;
          }
        }
      }
    }
  }

  if (SLItemUI?.items && SLItemUI?.items?.length > 0) {
    return <Box sx={{ pb: "100px" }}>
      <Container fixed>
        <StyledBox>
          {SLItemUI.items?.map((item: MainMenuItemData, index: number) =>
            <ThirdLevelMenuItem
              key={index}
              href={item.url}
              leftIcon={item.icon}
              label={item.label}
            />
          )}
        </StyledBox>
      </Container>
    </Box>
  } else {
    return <></>
  }
}

export default BrandCategories;

import React from 'react';
import Image from 'next/image';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { WPStandoutBanner } from '@/data/wp/operations/queries/fragments/fascia-standout-banner';
import CmsLinks from '@/components/ui/CmsLinks';

interface StandoutBannerProps extends WPStandoutBanner {}

const StandoutBanner: React.FC<StandoutBannerProps> = ({ titolo, descrizione, immagine, links }) => {

  return <Box sx={{ position: 'relative', pt: '90px', my: 5 }}>
    {immagine &&
      <Image
        layout="responsive"
        src={immagine.mediaItemUrl}
        alt={immagine.altText}
        width={immagine.mediaDetails.width}
        height={immagine.mediaDetails.height}
      />
    }
    <Box sx={{ position: { xs: 'relative', md: 'absolute'}, top: 0, left: 0, width: '100%', overflow: 'hidden' }}>
      <Container fixed sx={{ padding: { sm: '0 16px', xs: 0 } }}>
        <Grid container rowSpacing={0}>
          <Grid item xs={0} sm={2} md={6} lg={7} />
          <Grid item xs={11} sm={10} md={6} lg={5}>
            <Box sx={{ position: 'relative' }}>
              <Paper elevation={0} square={true} sx={{ position: 'absolute', top: 0, left: 0, right: '-1000px', height: '100%' }}></Paper>
              <Paper elevation={0} square={true} sx={{ position: 'relative', padding: { xs: '25px 32px 20px 0', md: '45px 32px 45px 65px' }}}>
                {titolo &&
                  <Typography variant="h2" mb={2.5}>{titolo}</Typography>
                }
                {descrizione &&
                  <Typography variant="subtitle2" mb={2.5}>{descrizione}</Typography>
                }
                {links &&
                  <CmsLinks data={links} direction="row" my={2}></CmsLinks>
                }
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </Box>
}

export default StandoutBanner;

import { styled } from "@mui/material/styles";
import usePagination from '@mui/material/usePagination';
import useTranslation from "next-translate/useTranslation";
import React from "react";

const Nav = styled("nav")({
  margin: "0 auto 60px"
});

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "inline-flex",
  border: "1px solid #e4e4e4",
  borderRadius: "12px"
});

const ListItem = styled("li")({
  borderLeft: "1px solid #e4e4e4",
  "&:first-of-type": {
    borderLeft: 0
  },
});

const ListItemButton = styled("button")({
  backgroundColor: "transparent",
  border: 0,
  padding: "12px 17px",
  fontSize: "15px",
  fontWeight: "600",
  letterSpacing: "-0.1px",
  lineHeight: "22px",
  textAlign: "center",
  cursor: "pointer",
  "&.selected": {
    backgroundColor: "#6b6b6b",
    color: "#ffffff",
  },
  "&[disabled]": {
    color: "#000000",
  }
});

interface CmpPaginationProps {
  prevButtonName?: string,
  nextButtonName?: string,
  page?: number,
  numOfPage?: number,
  onChange?: (event: React.ChangeEvent<unknown>, page: number) => void,
}

const CmpPagination: React.FC<CmpPaginationProps> = ({ prevButtonName, nextButtonName, page = 1, numOfPage, onChange }) => {
  const { t } = useTranslation('common');
  const { items } = usePagination({
    page,
    count: numOfPage,
    onChange,
  });

  const prevLabel = prevButtonName || t('pagination-prev-label', {}, {default: 'Precedente'});
  const nextLabel = nextButtonName || t('pagination-next-label', {}, {default: 'Successiva'});

  return (
    <Nav>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = <ListItemButton>…</ListItemButton>;
          } else if (type === "page") {
            children = (
              <ListItemButton
                type="button"
                className={selected ? "selected" : "" }
                {...item}
              >
                {page}
              </ListItemButton>
            );
          } else {
            const buttonLabel = type === "previous" ? prevLabel : nextLabel;
            children = (
              <ListItemButton type="button" {...item}>
                {buttonLabel}
              </ListItemButton>
            );
          }

          return <ListItem key={index}>{children}</ListItem>;
        })}
      </List>
    </Nav>
  )
}

export default CmpPagination;

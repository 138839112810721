import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { WPFullContent } from '@/data/wp/operations/queries/fragments/fascia-full-content';
import RichText from '@/components/ui/RichText';
import styled from '@mui/system/styled';
import theme from '@/mui/theme';

interface FullContentProps extends WPFullContent {
  _data: {
    _pageTitle?: string,
    _pageContent?: string,
  },
};

const StyledTitle = styled(Typography)({
  fontSize:  theme.typography.pxToRem(34),
  lineHeight: theme.typography.pxToRem(36),
  fontWeight: "700",
  letterSpacing: theme.typography.pxToRem(-0.23),
});

const FullContent: React.FC<FullContentProps> = ({ _data }) => {
  return <Box sx={{ position: 'relative', pt: '79px', pb: { sm: '68px', xs: '72px' } }}>
    <Container>
      <Grid container rowSpacing={0}>
        <Grid item xs={12} md={12} lg={12}>
          {_data?._pageTitle &&
            <StyledTitle>{_data?._pageTitle}</StyledTitle>
          }
          <RichText content={_data?._pageContent} />
        </Grid>
      </Grid>
    </Container>
  </Box>
}

export default FullContent;

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import styled from '@mui/system/styled';
import ChevronRight from "../icons/ChevronRight";
import Image from 'next/image';
import CmsLinks from '../ui/CmsLinks';
import { Swiper, SwiperSlide } from 'swiper/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { sanitize } from '@/utils/miscellaneous';
import { WPShowroomList } from '@/data/wp/operations/queries/fragments/fascia-showroom-list';

interface ShowroomListProps extends WPShowroomList {}

const StyledTitle = styled(Typography)({
  color: "#182641",
  fontSize: "24px",
  fontWeight: "bold",
  letterSpacing: "-0.1px",
  lineHeight: "30px",
  marginTop: '19px',
  marginBottom: "10px",
  textAlign: 'center'
});

const StyledDesc = styled(Typography)({
  color: "#000000",
  fontSize: "16px",
  letterSpacing: "0.1px",
  lineHeight: "24px",
  marginBottom: "0",
  textAlign: 'center',
});

const StyledLink = styled(CmsLinks)({
  fontSize: "14px",
  letterSpacing: "0",
  lineHeight: "17px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '5px',
  'a': {
    textDecoration: 'unset',
  }
});

const StyledSwiper = styled(Swiper)({
  '.swiper-wrapper': {
    left: '-7px',
  },
  '.swiper-slide': {
    width: 'calc(100% - 77px)',
  },
});

const ShowroomList: React.FC<ShowroomListProps> = ({ columns }) => {
  const matches = useMediaQuery('(min-width:600px)');

  return <Box sx={{ position: 'relative', pt: { sm: '65px', xs: '60px' }, pb: { sm: '90px', xs: '21px' } }}>
    <Container sx={{ padding: { sm: '0 16px', xs: 0 } }}>
      {matches && <>
        <Grid container rowSpacing={0}>
          {columns && columns.map((singleCol, index) => {
            return <Grid key={index} item xs={12} sm={4}>
              <Box sx={{ position: 'relative', mb: { sm: '0', xs: '54px' } }}>
                {singleCol.img &&
                  <Image
                    layout="responsive"
                    src={singleCol.img.mediaItemUrl}
                    alt={singleCol.img.altText}
                    width={singleCol.img.mediaDetails.width}
                    height={singleCol.img.mediaDetails.height}
                  />
                }
                {singleCol.title &&
                  <StyledTitle mb={2.5}>{singleCol.title}</StyledTitle>
                }
                {singleCol.desc &&
                  /* @ts-ignore */
                  <StyledDesc component="div" mb={2.5} px={7} dangerouslySetInnerHTML={{__html: sanitize(singleCol.desc)}} />
                }
                {singleCol.links && singleCol.links.length > 0 && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 1.2, color: "#FF5C04" }}>
                  <StyledLink data={singleCol.links} direction="row"></StyledLink>
                  <ChevronRight width="7px" height="11px" />
                </Box>}
              </Box>
            </Grid>
          })}
        </Grid>
      </>}
      {!matches &&
        <StyledSwiper
          spaceBetween={16}
          slidesPerView="auto"
          slidesPerGroup={1}
          centeredSlides={true}
        >
          {columns && columns.map((singleCol, index) => {
            return <SwiperSlide key={index}>
              <Grid item xs={12} sm={4}>
                <Box sx={{ position: 'relative', mb: { sm: '0', xs: '54px' } }}>
                  {singleCol.img &&
                    <Image
                      layout="responsive"
                      src={singleCol.img.mediaItemUrl}
                      alt={singleCol.img.altText}
                      width={singleCol.img.mediaDetails.width}
                      height={singleCol.img.mediaDetails.height}
                    />
                  }
                  {singleCol.title &&
                    <StyledTitle mb={2.5}>{singleCol.title}</StyledTitle>
                  }
                  {singleCol.desc &&
                    /* @ts-ignore */
                    <StyledDesc component="div" mb={2.5} px={6} dangerouslySetInnerHTML={{__html: sanitize(singleCol.desc)}} />
                  }
                  {singleCol.links && singleCol.links.length > 0 && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 1.2, color: "#FF5C04" }}>
                    <StyledLink data={singleCol.links} direction="row"></StyledLink>
                    <ChevronRight width="7px" height="11px" />
                  </Box>}
                </Box>
              </Grid>
            </SwiperSlide>
          })}
        </StyledSwiper>
      }
    </Container>
  </Box>
}

export default ShowroomList;

import React from 'react';
import { styled } from '@mui/material/styles';
import theme from '@/mui/theme';
import Box from '@mui/material/Box';
import { sanitize } from '@/utils/miscellaneous';
import InnerHTML from 'dangerously-set-html-content'

interface RichTextProps {
  content?: string
}

const StyledBox = styled(Box)({
  p: {
    margin: '24px 0',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: theme.typography.pxToRem(0.1),
  },
  h1: {
    margin: '24px 0',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(34),
    lineHeight: theme.typography.pxToRem(36),
    letterSpacing: theme.typography.pxToRem(-0.23),
  },
  h2: {
    margin: '24px 0',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(26),
    letterSpacing: theme.typography.pxToRem(-0.1),
  },
  h3: {
    margin: '24px 0',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(25),
    letterSpacing: theme.typography.pxToRem(0),
  },
  h4: {
    margin: '24px 0',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: theme.typography.pxToRem(0.1),
  },
  h5: {
    margin: '24px 0',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    letterSpacing: theme.typography.pxToRem(0),
  },
  h6: {
    margin: '24px 0',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    letterSpacing: theme.typography.pxToRem(0),
  },
  hr: {
    margin: '2em 0',
    border: 'none',
    height: '1px',
    background: '#333',
  },
  'ul, ol': {
    margin: '2em 0',
    padding: '0 0 0 30px',
    li: {
      margin: '8px 0',
    },
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
  },
  a: {
    color: '#000',
  }
}) as typeof Box;

/**
 * Renders a div containing the text from a Rich Text Editor field (i.e. with bold, lists, headings).
 * It can be used for example to print the text content of Realizzazioni, News etc.
 */
const RichText: React.FC<RichTextProps> = ({ content }) => {
  return content ?
    <StyledBox className="search-text">
      <InnerHTML html={sanitize( content ?? {}, false )}></InnerHTML>
    </StyledBox>
    : null;
}

export default RichText;

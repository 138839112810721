import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import styled from '@mui/system/styled';
import theme from '@/mui/theme';
import useTranslation from 'next-translate/useTranslation';
import { getIconComponentByName } from '@/utils/icons-map';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import { WPSearchHeading, WPSearchHeadingMode } from '@/data/wp/operations/queries/fragments/fascia-search-heading';

const StyledSearchTitle = styled(Typography)({
  fontSize: "24px",
  letterSpacing: "0",
  lineHeight: "28px",
  marginBottom: "3px",
  textAlign: 'left',
  color: '#ffffff',
  opacity: '0.96',
  [theme.breakpoints.down('sm')]: {
    fontSize: '15px',
    lineHeight: '22px'
  }
});

const StyledOutlinedInput = styled(OutlinedInput)({
  border: '0',
  borderRadius: '0',
  borderBottom: '2px solid rgba(255,255,255,0.35)',
  '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 0
  },
  '.MuiInputBase-input': {
    height: 'auto',
    color: '#ffffff',
    fontSize: "40px",
    letterSpacing: "-0.5px",
    lineHeight: "48px",
    padding: '6px 6px 6px 3px',
  },
  'svg': {
    width: '42px',
    height: '42px',
    color: '#ffffff',
    opacity: '0.6'
  },
  [theme.breakpoints.down('sm')]: {
    '.MuiInputBase-input': {
      fontSize: "36px",
      lineHeight: "42px",
    },
    'svg': {
      width: '30px',
      height: '30px',
    }
  }
});

const StyledResultFound = styled(Typography)({
  color: '#888f9d',
  fontSize: "15px",
  letterSpacing: "-0.1px",
  lineHeight: "22px",
  marginTop: '35px'
});

interface SearchHeadingProps extends WPSearchHeading {
  query?: string | null,
  numberResult: number,
  searchCallback?: (text: string) => void
}

/**
 * Mostra la fascia blu con titolo, campo di ricerca, submit button con lente e numero di risultati.
 *
 * Nota: gli elementi della pagina che devono essere oggetto di ricerca devono avere la classe "search-text".
 */
const SearchHeading: React.FC<SearchHeadingProps> = ({ mode, title, query = null, numberResult = 0, searchCallback }) => {
  const [queryState, setQueryState] = React.useState<string | null>(query);
  const [labelResultFound, setLabelResultFound] = React.useState<string>('');
  const { t } = useTranslation('common');

  const handleChangeInput = (event: any) => {
    setQueryState(event.target.value);
  }

  const handleSubmitSearch = () => {
    if (!queryState || queryState.length < 3) {
      // almeno 3 caratteri

    } else {
      if (mode === WPSearchHeadingMode.inpage) {
        // scroll to first result
        document.querySelector('.search-text mark')?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });

      } else if (searchCallback) {
        searchCallback(queryState)
      }
    }
  }

  const handleKeyToSubmit = (event: { key: string; }) => {
    if (event.key === 'Enter') {
      console.log('Enter Pressed');
      handleSubmitSearch();
    }
  }

  // quando cambia la prop aggiorno lo stato interno al componente
  useEffect(() => {
    setQueryState(query);
  }, [query]);

  useEffect(() => {
    const reg = new RegExp(`(${queryState})`, 'gi');

    /** before remove mark if already present inside the text */
    document.querySelectorAll('mark')!.forEach((element) => {
      const oldMarkWord = element.innerHTML;
      return element.outerHTML = oldMarkWord;
    });

    /** if searched word is present so mark word inside the page */
    document.querySelectorAll('.search-text')!.forEach((element) => {
      if (queryState && queryState.length >= 3) {
        return element.innerHTML = element.innerHTML.replaceAll(reg, '<mark>$1</mark>');
      }
    });
  }, [mode, queryState]);

  useEffect(() => {
    setLabelResultFound(t('find-results', { count: numberResult }));
  }, [t, numberResult]);

  return <Box sx={{ position: 'relative', p: { sm: '60px', xs: '60px 14px' }, backgroundColor: '#182641' }}>
    <Container>
      <Grid container rowSpacing={0}>
        <Grid item xs={12}>
          {title &&
            <StyledSearchTitle>{title}</StyledSearchTitle>
          }
          <FormControl variant="standard" fullWidth>
            <StyledOutlinedInput
              id="search"
              type="text"
              value={queryState || ''}
              onChange={handleChangeInput}
              onKeyPress={handleKeyToSubmit}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('search-icon', {}, {default: 'Search icon'})}
                    onClick={handleSubmitSearch}
                    onMouseDown={handleSubmitSearch}
                    edge="end"
                  >
                    {getIconComponentByName('search')}
                  </IconButton>
                </InputAdornment>
              }
              placeholder={t('header-search-placeholder', {})}
            />
          </FormControl>
          {mode === WPSearchHeadingMode.backend &&
            <StyledResultFound>{labelResultFound}</StyledResultFound>
          }
        </Grid>
      </Grid>
    </Container>
  </Box>
}

export default SearchHeading;

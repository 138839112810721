import React from 'react';
import theme from '@/mui/theme';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { WhiteCardProps } from '../ui/WhiteCard';
import WhiteCard from '@/components/ui/WhiteCard';
import OmniLink from '../ui/OmniLink';
import styled from '@mui/system/styled';
import Typography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';

import placeholderProductGray from '@/public/placeholder-product-gray.svg';

interface CardListProps {
  title?: string;
  url?: string;
  urlLabel?: string;
  is3Col?: boolean;
  whiteCardList: WhiteCardProps[];
  bgGray?: boolean;
  cta?: React.ReactNode;
  subtitleResults?: number;
  hideImages?: boolean;
}

const StyledTitle = styled(Typography)({
  fontSize: "34px",
  fontWeight: "bold",
  letterSpacing: "-0.23px",
  lineHeight: "32px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "24px",
    letterSpacing: "-0.1px",
    lineHeight: "30px",
  }
});

const StyledSubtitle = styled(Typography)({
  fontSize: "18px",
  letterSpacing: "0",
  lineHeight: "28px",
  marginLeft: "13px",
  [theme.breakpoints.down('sm')]: {
    marginLeft: "0",
    fontSize: "13px",
    lineHeight: "normal"
  }
});

const StyledCta = styled(OmniLink)({
  fontSize: "13px",
  letterSpacing: "0",
  lineHeight: "17px",
  color: "#000000",
  textDecorationColor: "#000000",
});

const CardList: React.FC<CardListProps> = ({ title, url, urlLabel, cta, is3Col = false, whiteCardList, bgGray, subtitleResults, hideImages = false}) => {
  const { t } = useTranslation('common');
  const labelResultFound = t('find-results', { count: subtitleResults || 0 });

  if(whiteCardList && whiteCardList.length > 0){
    return <Box sx={{ position: 'relative', py: '50px', backgroundColor: bgGray ? '#cccfd4' : '' }}>
      <Container>
        <Grid container rowSpacing={0}>
          {(title || subtitleResults) && !cta &&
            <Grid item xs={7} sm={10} display="flex" alignItems="baseline" sx={{ flexDirection: { sm: 'row', xs: 'column' } }}>
              {title &&
                <StyledTitle sx={{ marginBottom: "35px" }}>{title}</StyledTitle>
              }
              {subtitleResults !== undefined && subtitleResults >= 0 &&
                <StyledSubtitle>{labelResultFound}</StyledSubtitle>
              }
            </Grid>
          }
          {url && urlLabel && !cta &&
            <Grid item xs={5} sm={2} sx={{
              display: "flex",
              marginBottom: "35px",
              alignItems: {
                sm: "center",
                xs: "flex-end",
              },
              justifyContent: "flex-end",}}>
                <StyledCta href={url}>{urlLabel}</StyledCta>
            </Grid>
          }
          {cta &&
            <Grid item xs={12} sx={{ borderBottom: '2px solid #888f9d', pl: '0!important', ml: '32px', pb: '15px', mb: '20px' }}>
              <Grid container rowSpacing={0} alignItems="center">
                <Grid item sm={8} xs={7} display="flex" alignItems="baseline" sx={{ flexDirection: { sm: 'row', xs: 'column' } }}>
                  {title &&
                    <StyledTitle mb={0} sx={{ lineHeight: 'normal' }}>{title}</StyledTitle>
                  }
                  <StyledSubtitle>{labelResultFound}</StyledSubtitle>
                </Grid>
                <Grid item sm={4} xs={5} sx={{
                  display: "flex",
                  alignItems: {
                    sm: "start",
                    xs: "flex-end",
                  },
                  justifyContent: "end"
                  }}>
                    {cta}
                </Grid>
              </Grid>
            </Grid>
          }
          {whiteCardList && whiteCardList.length > 0 &&
            whiteCardList.map((element, key) => {
              return (
                <Grid key={key} item xs={6} sm={is3Col ? 4 : 3} sx={{ marginBottom: "35px" }}>
                  {/* imagePlaceholderTransparent */}
                  <WhiteCard {...element} img={hideImages ? undefined : element.img} placeholderImage={hideImages ? undefined : placeholderProductGray}></WhiteCard>
                </Grid>
              )
            })
          }
        </Grid>
      </Container>
    </Box>
    }
  return <Box></Box>
}

export default CardList;

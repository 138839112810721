import React from 'react';
import { WPProductList } from '@/data/wp/operations/queries/fragments/fascia-product-list';
import CardList from './CardList';

interface ProductListProps extends WPProductList {
  _data: {
    _products: any[]
  },
}

const ProductList: React.FC<ProductListProps> = ({
  _data, title, grayBackground, viewAllUrl, viewAllLabel, layout3Col
}) => {
  if (_data?._products?.length > 0) {
    return <CardList
      title={title}
      bgGray={grayBackground}
      url={viewAllUrl}
      urlLabel={viewAllLabel}
      whiteCardList={_data?._products}
      is3Col={layout3Col}
    />
  } else {
    return <></>
  }
}

export default ProductList;

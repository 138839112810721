import React, { useMemo, useState, useEffect } from 'react';
import isEmpty from "lodash/isEmpty";
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDataPagination } from '@/utils/pagination';
import { buildPageUrl, goToPaginationPageUrl, PageType } from '@/utils/router';
import styled from '@mui/system/styled';
import theme from '@/mui/theme';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { WPRealizzazioniList } from '@/data/wp/operations/queries/fragments/fascia-realizzazioni-list';
import OmniLink from '@/components/ui/OmniLink';
import CmpPagination from '@/components/ui/Pagination';
import GrayCard from '@/components/ui/GrayCard';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledHead = styled(Stack)({
  marginBottom: "25px",
});

const StyledTitle = styled(Typography)({
  fontSize: "34px",
  fontWeight: "bold",
  letterSpacing: "-0.23px",
  lineHeight: "32px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "24px",
    letterSpacing: "-0.1px",
    lineHeight: "30px",
  }
});

const StyledCta = styled(OmniLink)({
  fontSize: "13px",
  letterSpacing: "0",
  lineHeight: "17px",
  color: "#000000",
  textDecorationColor: "#000000",
  whiteSpace: "nowrap",
  [theme.breakpoints.down('sm')]: {
    position: "relative",
    bottom: "5px",
  }
});

const StyledCategories = styled(Stack)({
  marginBottom: "50px",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  flexWrap: 'wrap',
  '& .MuiToggleButtonGroup-grouped': {
    margin: '7px 14px',
    padding: '17px',
    border: 0,
    '&.MuiToggleButton-root span': {
      display: 'inline-block',
      marginLeft: '60px',
      fontWeight: 'normal',
    },
    '&.MuiToggleButton-standard': {
      background: '#f1f1f1',
      color: '#182641',
      fontSize: '16px',
      lineHeight: '20px',
    },
    '&.Mui-selected': {
      background: '#182641',
      color: '#fff',
    },
    '&.MuiToggleButton-standard:hover': {
      background: '#182641',
      color: '#fff',
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      marginLeft: 0,
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const StyledSwiper = styled(Swiper)({
  '&.swiper-container': {
    marginLeft: '15px',
  },
  '&.swiper-container-horizontal': {
    position: 'unset',
  },
  '.swiper-slide': {
    width: 'calc(100vw - 50px)',
  },
});

interface RealizzazioniListProps extends WPRealizzazioniList {
  _data: {
    _mainCategorySlug: string,
    _subCategories?: any,
    _subCategoriesCounter?: any,
    _posts: any,
  },
}

/**
 *
 * Esempio per pagina elenco realizzazioni:
 *  <RealizzazioniList data={mainCategorySlug="projects", subCategories: {..} posts: {..}} showCategoryFilters={true}></RealizzazioniList>
 *
 * Esempio per homepage:
 *  <RealizzazioniList data={mainCategorySlug="projects", posts: {...}} title="Alcune realizzazioni" showViewAllLink showFeaturedOnly={true} sliderOnMobile={true}></RealizzazioniList>
 *
 */
const RealizzazioniList: React.FC<RealizzazioniListProps> = ({
  _data, showCategoryFilters = false, showFeaturedOnly = false, showViewAllLink = false, sliderOnMobile = false, title
}) => {
  const featuredOnlyMaxItems = 4;
  const isMobileBp = useMediaQuery('(max-width: 599px)');
  const { t } = useTranslation('common');
  const router = useRouter();
  const {locale} = router;
  const [page, setPage] = useState(1);
  const [subCategoryId, setSubCategoryId] = useState<number | null>(null);

  const filteredData = useMemo(() => _data?._posts?.filter((item: any) => {
    const itemData = item?.node;
    if (subCategoryId && itemData._subCategory && subCategoryId !== itemData._subCategory?.node?.id) {
      return false;
    } else {
      return true;
    }
  }), [_data?._posts, subCategoryId]);

  const pageSize = parseInt(process.env.NEXT_PUBLIC_POST_2COL_PAGE_SIZE || '10');
  const pagesCount = Math.ceil((filteredData?.length || 0) / pageSize);
  const enablePagination = !showFeaturedOnly && pagesCount > 1;
  const paginatedData = useDataPagination(filteredData, showFeaturedOnly ? featuredOnlyMaxItems: pageSize);

  function goToPage(page: number) {
    setPage(page);
    paginatedData.jump(page);
    goToPaginationPageUrl(router, page);
  }

  /* @ts-ignore */
  const handleSubCategory = (e: any, subCategoryId: number | null) => {
    goToPage(1);
    setSubCategoryId(subCategoryId);
  };

  // Resetto filtri e paginazione al cambio lingua
  useEffect(() => {
    if (enablePagination) {
      goToPage(1);
    }
    setSubCategoryId(null);
  // Ometto volutamente le dipendenze enablePagination e goToPage.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  if (!filteredData) {
    return <></>
  }

  const showSlider = sliderOnMobile && isMobileBp;

  return <Box sx={{ py: "60px" }}>
    <Container fixed sx={{ padding: '0 16px' }}>
      {(title || showViewAllLink) &&
        <StyledHead direction="row" spacing={2} justifyContent="space-between" alignItems="end">
          {title &&
            <StyledTitle>{title}</StyledTitle>
          }
          {showViewAllLink &&
            <StyledCta href={buildPageUrl(PageType.POST_CATEGORY, { category: _data?._mainCategorySlug })}>
              {t('realizzazioni-vedi-tutte', {})}
            </StyledCta>
          }
        </StyledHead>
      }
      {showCategoryFilters && !isEmpty(_data?._subCategories) &&
        <StyledCategories direction="row" spacing={2}>
          <StyledToggleButtonGroup
              value={subCategoryId}
              exclusive
              onChange={handleSubCategory}
          >
            {_data?._subCategories?.map((subCat: any, index: number) => {
              const count = _data?._subCategoriesCounter[subCat?.node?.id];
              if (count) {
                return <ToggleButton key={index} value={subCat?.node?.id} size="large" disableRipple={true}>
                  {subCat?.node?.name} <span>{count}</span>
                </ToggleButton>
              }
            })}
          </StyledToggleButtonGroup>
        </StyledCategories>
      }
    </Container>
    {showSlider &&
      <StyledSwiper
        spaceBetween={15}
        slidesPerView="auto"
        slidesPerGroup={1}
        loop={true}
      >
        {paginatedData.currentData().map((item: any, index: number) => {
          const itemData = item?.node;
          const detailUrl = buildPageUrl(PageType.POST_DETAIL, { category: _data?._mainCategorySlug, slug: itemData?.slug });
          return (
            <SwiperSlide key={index}>
              <GrayCard
                  tag={itemData._subCategory?.node?.name}
                  url={detailUrl}
                  img={itemData.featuredImage?.node}
                  occhiello={itemData.fieldsRealizzazioni?.locality}
                  title={itemData.title}
                  text={itemData.excerpt}
                />
            </SwiperSlide>
          )
        })}
      </StyledSwiper>
    }
    {!showSlider &&
      <Container fixed sx={{ padding: '0 16px' }}>
        <Grid container>
          {paginatedData.currentData().map((item: any, index: number) => {
            const itemData = item?.node;
            const detailUrl = buildPageUrl(PageType.POST_DETAIL, { category: _data?._mainCategorySlug, slug: itemData?.slug });
            return (
              <Grid key={index} item sm={6} sx={{display: 'flex', width: '100%'}}>
                <GrayCard
                  tag={itemData._subCategory?.node?.name}
                  url={detailUrl}
                  img={itemData.featuredImage?.node}
                  occhiello={itemData.fieldsRealizzazioni?.locality}
                  title={itemData.title}
                  text={itemData.excerpt}
                />
              </Grid>
            )
          })}
        </Grid>
      </Container>
    }
    {enablePagination &&
      <Container fixed sx={{ padding: '0 16px', mt: '80px' }}>
        <Stack direction="row">
          {/* @ts-ignore */}
          <CmpPagination page={page} numOfPage={pagesCount} onChange={(e: any, page: number) => goToPage(page)} />
        </Stack>
      </Container>
    }
  </Box>
}

export default RealizzazioniList;

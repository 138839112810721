import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import styled from '@mui/system/styled';
import theme from '@/mui/theme';
import { WPColumnText } from '@/data/wp/operations/queries/fragments/fascia-column-text';

interface ColumnTextProps extends WPColumnText {}

const StyledTitle = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    height: '70px',
    marginBottom: "39px",
  },
  color: "#182641",
  fontSize: "34px",
  fontWeight: "bold",
  letterSpacing: "-0.23px",
  lineHeight: "35px",
  marginBottom: "25px",
  height: 'auto',
  overflow: 'hidden',
});

const StyledDesc = styled(Typography)({
  color: "#000000",
  fontSize: "16px",
  letterSpacing: "0.1px",
  lineHeight: "24px",
  marginBottom: "0",
});

const ColumnText: React.FC<ColumnTextProps> = ({ columns }) => {
  return <Box sx={{ position: 'relative', pt: { sm: '65px', xs: '60px' }, pb: { sm: '90px', xs: '21px' } }}>
    <Container>
      <Grid container rowSpacing={0}>
        {columns && columns.map((singleCol, index) => {
          return <Grid key={index} item xs={12} sm={4}>
            <Box sx={{ position: 'relative', mb: { sm: '0', xs: '54px' }, paddingRight: '25px' }}>
              {singleCol.title &&
                <StyledTitle mb={2.5}>{singleCol.title}</StyledTitle>
              }
              {singleCol.desc &&
                <StyledDesc mb={2.5}>{singleCol.desc}</StyledDesc>
              }
            </Box>
          </Grid>
        })}
      </Grid>
    </Container>
  </Box>
}

export default ColumnText;

import React from 'react';
import isEmpty from "lodash/isEmpty";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import StandoutBanner from '@/components/fasce/StandoutBanner';
import Hero from '@/components/fasce/Hero';
import WideBanners from '@/components/fasce/WideBanners';
import RealizzazioniList from '@/components/fasce/RealizzazioniList';
import NewsEventiFiereList from '@/components/fasce/NewsEventiFiereList';
import ProductList from '@/components/fasce/ProductList';
import FullBanner from '@/components/fasce/FullBanner';
import ColumnText from '@/components/fasce/ColumnText';
import GroupSlider from '@/components/fasce/GroupSlider';
import FullDoubleColumn from '@/components/fasce/FullDoubleColumn';
import CenteredTextIcon from '@/components/fasce/CenteredTextIcon';
import Mission from '@/components/fasce/Mission';
import Normative from '@/components/fasce/Normative';
import ShowroomList from '@/components/fasce/ShowroomList';
import TopAnchorNav from '@/components/fasce/TopAnchorNav';
import SidedContent from '@/components/fasce/SidedContent';
import FullContent from '@/components/fasce/FullContent';
import Contacts from '@/components/fasce/Contacts';
import PostList from '@/components/fasce/PostList';
import SearchHeading from '@/components/fasce/SearchHeading';
import Spacer from '@/components/fasce/Spacer';
import BrandCategories from '@/components/fasce/BrandCategories';

import { WPTranslation } from '@/data/wp/operations/queries/fragments/languages';

interface FasceProps {
  fasce: any,
  translations?: WPTranslation[], // Current page translations
}

const Fasce: React.FC<FasceProps> = ({ fasce = {}, translations }) => {
  if (isEmpty(fasce) || fasce?.fasce?.length === 0) {
    return null;
  }

  return (<div>
    {fasce.fasce.map((fascia: any, index: number) => {
      return <div key={index} id={`box-${index}`}>
        {switcherFasce(fascia, translations)}
      </div>
    })}
  </div>);
};

export default Fasce;

const switcherFasce = (fascia: any, translations?: WPTranslation[]) => {
  const type = fascia.__typename.split('_').pop();
  switch (type) {
    case 'Hero':
      return <Hero {...fascia}></Hero>;
    case 'StandoutBanner':
      return <StandoutBanner {...fascia}></StandoutBanner>;
    case 'WideBanners':
      return <WideBanners {...fascia}></WideBanners>;
    case 'RealizzazioniList':
      return <RealizzazioniList {...fascia}></RealizzazioniList>;
    case 'NewsEventiFiereList':
      return <NewsEventiFiereList {...fascia}></NewsEventiFiereList>;
    case 'ProductList':
      return <ProductList {...fascia}></ProductList>;
    case 'FullBanner':
      return <FullBanner {...fascia}></FullBanner>;
    case 'ColumnText':
      return <ColumnText {...fascia}></ColumnText>;
    case 'GroupSlider':
      return <GroupSlider {...fascia}></GroupSlider>;
    case 'FullDoubleColumn':
      return <FullDoubleColumn {...fascia}></FullDoubleColumn>;
    case 'CenteredTextIcon':
      return <CenteredTextIcon {...fascia}></CenteredTextIcon>;
    case 'Mission':
      return <Mission {...fascia}></Mission>;
    case 'Normative':
      return <Normative {...fascia}></Normative>;
    case 'ShowroomList':
      return <ShowroomList {...fascia}></ShowroomList>;
    case 'TopAnchorNav':
      return <TopAnchorNav {...fascia}></TopAnchorNav>;
    case 'SidedContent':
      return <SidedContent {...fascia}></SidedContent>;
    case 'FullContent':
      return <FullContent {...fascia}></FullContent>;
    case 'Contacts':
      return <Contacts {...fascia}></Contacts>;
    case 'PostList':
      return <PostList {...fascia} translations={translations}></PostList>;
    case 'SearchHeading':
      return <SearchHeading {...fascia}></SearchHeading>;
    case 'Spacer':
      return <Spacer {...fascia}></Spacer>;
    case 'BrandCategories':
      return <BrandCategories {...fascia}></BrandCategories>
    default:
      return <Container sx={{pt: 5}} fixed><Typography>{`Could not find React Component for fascia of type '${type}' coming from CMS`}</Typography></Container>;
  }
};

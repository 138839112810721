import React from 'react';
import Image from 'next/image';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import CmsLinks from '../ui/CmsLinks';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '@/mui/theme';
import { WPWideBanners } from '@/data/wp/operations/queries/fragments/fascia-wide-banners';
import { sanitize } from '@/utils/miscellaneous';

interface WideBannersProps extends WPWideBanners {}

const StyledTitle = styled(Typography)({
  color: '#ffffff',
  fontSize: '34px',
  fontWeight: 'bold',
  letterSpacing: '-0.23px',
  lineHeight: '32px',
  marginBottom: '23px',
});

const StyledText = styled(Typography)({
  fontSize: '22px',
  letterSpacing: '-0.31px',
  lineHeight: '28px',
  marginBottom: '20px',
  p: {
    margin: 0,
  },
});

const StyledTextWhite = styled(Typography)({
  color: '#ffffff',
  fontSize: '22px',
  letterSpacing: '-0.31px',
  lineHeight: '28px',
  marginBottom: '20px',
  p: {
    margin: 0,
  },
});

const StyledCta = styled(CmsLinks)({
  '> a': {
    [theme.breakpoints.down('sm')]: {
      padding: '15px 12px'
    }
  }
});

const ImageBox = styled(Box)({
  fontSize: 0,
  lineHeight: 0,
});

const Box1 = styled(Box)({
  flexGrow: '8',
  [theme.breakpoints.down('md')]: {
    position: 'relative',
  },
  [theme.breakpoints.up('md')]: {
    marginRight: '15px',
  }
});

const Box2 = styled(Box)({
  position: 'relative',
  flexGrow: '4',
  '& span': {
    padding: '52.518%!important'
  }
});

const ContentBox1 = styled(Box)({
  position: 'absolute',
  top: 0,
  left: '50%',
  marginLeft: '-555px',
  height: '100%',
  width: '350px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '-426px',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '50px',
    marginLeft: 0,
    left: 0,
    padding: '32px',
    width: '100%',
  },
  [theme.breakpoints.only('sm')]: {
    left: '50%',
    marginLeft: '-305px',
  },
});

const ContentBox2 = styled(Box)({
  position: 'absolute',
  top: 0,
  left: '12%',
  height: '100%',
  width: '260px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    left: 0,
    padding: '32px',
    width: '100%',
  },
  [theme.breakpoints.only('sm')]: {
    left: '50%',
    marginLeft: '-305px',
  },
});

const WideBanners: React.FC<WideBannersProps> = ({ title1, text1, links1Links, immagine1Desktop, immagine1Mobile, title2, text2, links2Links, immagine2Desktop, immagine2Mobile }) => {
  const matches = useMediaQuery('(min-width:900px)');

  return (<>
    <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, position: 'relative', maxWidth: '100%', overflow: 'hidden' }}>

      <Box1>
        <ImageBox>
          {immagine1Desktop && matches &&
            <Image
              layout="responsive"
              src={immagine1Desktop.mediaItemUrl}
              alt={immagine1Desktop.altText}
              width={immagine1Desktop.mediaDetails.width}
              height={immagine1Desktop.mediaDetails.height}
            />
          }
          {immagine1Mobile && !matches &&
            <Image
              src={immagine1Mobile.mediaItemUrl}
              alt={immagine1Mobile.altText}
              width={immagine1Mobile.mediaDetails.width}
              height={immagine1Mobile.mediaDetails.height}
            />
          }
        </ImageBox>
        <ContentBox1>
          {title1 &&
            <StyledTitle mb={2.5}>{title1}</StyledTitle>
          }
          {text1 &&
            /* @ts-ignore */
            <StyledText component="div" mb={2.5} dangerouslySetInnerHTML={{__html: sanitize(text1)}}></StyledText>
          }
          {links1Links &&
            <StyledCta data={links1Links} direction='row' my={2}></StyledCta>
          }
        </ContentBox1>
      </Box1>

      <Box2>
        <ImageBox>
          {immagine2Desktop && matches &&
            <Image
              layout="responsive"
              src={immagine2Desktop.mediaItemUrl}
              alt={immagine2Desktop.altText}
              width={immagine2Desktop.mediaDetails.width}
              height={immagine2Desktop.mediaDetails.height}
            />
          }
          {immagine2Mobile && !matches &&
            <Image
              src={immagine2Mobile.mediaItemUrl}
              alt={immagine2Mobile.altText}
              width={immagine2Mobile.mediaDetails.width}
              height={immagine2Mobile.mediaDetails.height}
            />
          }
        </ImageBox>
        <ContentBox2>
          {title2 &&
            <StyledTitle mb={2.5}>{title2}</StyledTitle>
          }
          {text2 &&
            /* @ts-ignore */
            <StyledTextWhite component="div" mb={2.5} dangerouslySetInnerHTML={{__html: sanitize(text2)}}></StyledTextWhite>
          }
          {links2Links &&
            <StyledCta data={links2Links} direction='row' my={2}></StyledCta>
          }
        </ContentBox2>
      </Box2>

    </Box>
  </>)
}

export default WideBanners;
